<div class='icons'>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_500px"></malb-icon>
		<p>brands_500px</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_accessible_icon"></malb-icon>
		<p>brands_accessible_icon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_accusoft"></malb-icon>
		<p>brands_accusoft</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_acquisitions_incorporated"></malb-icon>
		<p>brands_acquisitions_incorporated</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_adn"></malb-icon>
		<p>brands_adn</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_adversal"></malb-icon>
		<p>brands_adversal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_affiliatetheme"></malb-icon>
		<p>brands_affiliatetheme</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_airbnb"></malb-icon>
		<p>brands_airbnb</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_algolia"></malb-icon>
		<p>brands_algolia</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_alipay"></malb-icon>
		<p>brands_alipay</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_amazon_pay"></malb-icon>
		<p>brands_amazon_pay</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_amazon"></malb-icon>
		<p>brands_amazon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_amilia"></malb-icon>
		<p>brands_amilia</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_android"></malb-icon>
		<p>brands_android</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_angellist"></malb-icon>
		<p>brands_angellist</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_angrycreative"></malb-icon>
		<p>brands_angrycreative</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_angular"></malb-icon>
		<p>brands_angular</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_app_store_ios"></malb-icon>
		<p>brands_app_store_ios</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_app_store"></malb-icon>
		<p>brands_app_store</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_apper"></malb-icon>
		<p>brands_apper</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_apple_pay"></malb-icon>
		<p>brands_apple_pay</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_apple"></malb-icon>
		<p>brands_apple</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_artstation"></malb-icon>
		<p>brands_artstation</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_asymmetrik"></malb-icon>
		<p>brands_asymmetrik</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_atlassian"></malb-icon>
		<p>brands_atlassian</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_audible"></malb-icon>
		<p>brands_audible</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_autoprefixer"></malb-icon>
		<p>brands_autoprefixer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_avianex"></malb-icon>
		<p>brands_avianex</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_aviato"></malb-icon>
		<p>brands_aviato</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_aws"></malb-icon>
		<p>brands_aws</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_bandcamp"></malb-icon>
		<p>brands_bandcamp</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_battle_net"></malb-icon>
		<p>brands_battle_net</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_behance_square"></malb-icon>
		<p>brands_behance_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_behance"></malb-icon>
		<p>brands_behance</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_bimobject"></malb-icon>
		<p>brands_bimobject</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_bitbucket"></malb-icon>
		<p>brands_bitbucket</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_bitcoin"></malb-icon>
		<p>brands_bitcoin</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_bity"></malb-icon>
		<p>brands_bity</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_black_tie"></malb-icon>
		<p>brands_black_tie</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_blackberry"></malb-icon>
		<p>brands_blackberry</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_blogger_b"></malb-icon>
		<p>brands_blogger_b</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_blogger"></malb-icon>
		<p>brands_blogger</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_bluetooth_b"></malb-icon>
		<p>brands_bluetooth_b</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_bluetooth"></malb-icon>
		<p>brands_bluetooth</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_bootstrap"></malb-icon>
		<p>brands_bootstrap</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_btc"></malb-icon>
		<p>brands_btc</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_buffer"></malb-icon>
		<p>brands_buffer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_buromobelexperte"></malb-icon>
		<p>brands_buromobelexperte</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_buy_n_large"></malb-icon>
		<p>brands_buy_n_large</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_buysellads"></malb-icon>
		<p>brands_buysellads</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_canadian_maple_leaf"></malb-icon>
		<p>brands_canadian_maple_leaf</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cc_amazon_pay"></malb-icon>
		<p>brands_cc_amazon_pay</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cc_amex"></malb-icon>
		<p>brands_cc_amex</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cc_apple_pay"></malb-icon>
		<p>brands_cc_apple_pay</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cc_diners_club"></malb-icon>
		<p>brands_cc_diners_club</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cc_discover"></malb-icon>
		<p>brands_cc_discover</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cc_jcb"></malb-icon>
		<p>brands_cc_jcb</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cc_mastercard"></malb-icon>
		<p>brands_cc_mastercard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cc_paypal"></malb-icon>
		<p>brands_cc_paypal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cc_stripe"></malb-icon>
		<p>brands_cc_stripe</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cc_visa"></malb-icon>
		<p>brands_cc_visa</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_centercode"></malb-icon>
		<p>brands_centercode</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_centos"></malb-icon>
		<p>brands_centos</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_chrome"></malb-icon>
		<p>brands_chrome</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_chromecast"></malb-icon>
		<p>brands_chromecast</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cloudflare"></malb-icon>
		<p>brands_cloudflare</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cloudscale"></malb-icon>
		<p>brands_cloudscale</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cloudsmith"></malb-icon>
		<p>brands_cloudsmith</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cloudversify"></malb-icon>
		<p>brands_cloudversify</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_codepen"></malb-icon>
		<p>brands_codepen</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_codiepie"></malb-icon>
		<p>brands_codiepie</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_confluence"></malb-icon>
		<p>brands_confluence</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_connectdevelop"></malb-icon>
		<p>brands_connectdevelop</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_contao"></malb-icon>
		<p>brands_contao</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cotton_bureau"></malb-icon>
		<p>brands_cotton_bureau</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cpanel"></malb-icon>
		<p>brands_cpanel</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_by"></malb-icon>
		<p>brands_creative_commons_by</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_nc_eu"></malb-icon>
		<p>brands_creative_commons_nc_eu</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_nc_jp"></malb-icon>
		<p>brands_creative_commons_nc_jp</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_nc"></malb-icon>
		<p>brands_creative_commons_nc</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_nd"></malb-icon>
		<p>brands_creative_commons_nd</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_pd_alt"></malb-icon>
		<p>brands_creative_commons_pd_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_pd"></malb-icon>
		<p>brands_creative_commons_pd</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_remix"></malb-icon>
		<p>brands_creative_commons_remix</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_sa"></malb-icon>
		<p>brands_creative_commons_sa</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_sampling_plus"></malb-icon>
		<p>brands_creative_commons_sampling_plus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_sampling"></malb-icon>
		<p>brands_creative_commons_sampling</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_share"></malb-icon>
		<p>brands_creative_commons_share</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons_zero"></malb-icon>
		<p>brands_creative_commons_zero</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_creative_commons"></malb-icon>
		<p>brands_creative_commons</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_critical_role"></malb-icon>
		<p>brands_critical_role</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_css3_alt"></malb-icon>
		<p>brands_css3_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_css3"></malb-icon>
		<p>brands_css3</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_cuttlefish"></malb-icon>
		<p>brands_cuttlefish</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_d_and_d_beyond"></malb-icon>
		<p>brands_d_and_d_beyond</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_d_and_d"></malb-icon>
		<p>brands_d_and_d</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_dailymotion"></malb-icon>
		<p>brands_dailymotion</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_dashcube"></malb-icon>
		<p>brands_dashcube</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_deezer"></malb-icon>
		<p>brands_deezer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_delicious"></malb-icon>
		<p>brands_delicious</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_deploydog"></malb-icon>
		<p>brands_deploydog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_deskpro"></malb-icon>
		<p>brands_deskpro</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_dev"></malb-icon>
		<p>brands_dev</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_deviantart"></malb-icon>
		<p>brands_deviantart</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_dhl"></malb-icon>
		<p>brands_dhl</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_diaspora"></malb-icon>
		<p>brands_diaspora</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_digg"></malb-icon>
		<p>brands_digg</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_digital_ocean"></malb-icon>
		<p>brands_digital_ocean</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_discord"></malb-icon>
		<p>brands_discord</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_discourse"></malb-icon>
		<p>brands_discourse</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_dochub"></malb-icon>
		<p>brands_dochub</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_docker"></malb-icon>
		<p>brands_docker</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_draft2digital"></malb-icon>
		<p>brands_draft2digital</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_dribbble_square"></malb-icon>
		<p>brands_dribbble_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_dribbble"></malb-icon>
		<p>brands_dribbble</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_dropbox"></malb-icon>
		<p>brands_dropbox</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_drupal"></malb-icon>
		<p>brands_drupal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_dyalog"></malb-icon>
		<p>brands_dyalog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_earlybirds"></malb-icon>
		<p>brands_earlybirds</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ebay"></malb-icon>
		<p>brands_ebay</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_edge_legacy"></malb-icon>
		<p>brands_edge_legacy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_edge"></malb-icon>
		<p>brands_edge</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_elementor"></malb-icon>
		<p>brands_elementor</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ello"></malb-icon>
		<p>brands_ello</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ember"></malb-icon>
		<p>brands_ember</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_empire"></malb-icon>
		<p>brands_empire</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_envira"></malb-icon>
		<p>brands_envira</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_erlang"></malb-icon>
		<p>brands_erlang</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ethereum"></malb-icon>
		<p>brands_ethereum</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_etsy"></malb-icon>
		<p>brands_etsy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_evernote"></malb-icon>
		<p>brands_evernote</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_expeditedssl"></malb-icon>
		<p>brands_expeditedssl</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_facebook_f"></malb-icon>
		<p>brands_facebook_f</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_facebook_messenger"></malb-icon>
		<p>brands_facebook_messenger</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_facebook_square"></malb-icon>
		<p>brands_facebook_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_facebook"></malb-icon>
		<p>brands_facebook</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_fantasy_flight_games"></malb-icon>
		<p>brands_fantasy_flight_games</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_fedex"></malb-icon>
		<p>brands_fedex</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_fedora"></malb-icon>
		<p>brands_fedora</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_figma"></malb-icon>
		<p>brands_figma</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_firefox_browser"></malb-icon>
		<p>brands_firefox_browser</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_firefox"></malb-icon>
		<p>brands_firefox</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_first_order_alt"></malb-icon>
		<p>brands_first_order_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_first_order"></malb-icon>
		<p>brands_first_order</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_firstdraft"></malb-icon>
		<p>brands_firstdraft</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_flickr"></malb-icon>
		<p>brands_flickr</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_flipboard"></malb-icon>
		<p>brands_flipboard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_fly"></malb-icon>
		<p>brands_fly</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_font_awesome_alt"></malb-icon>
		<p>brands_font_awesome_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_font_awesome_flag"></malb-icon>
		<p>brands_font_awesome_flag</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_font_awesome_logo_full"></malb-icon>
		<p>brands_font_awesome_logo_full</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_font_awesome"></malb-icon>
		<p>brands_font_awesome</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_fonticons_fi"></malb-icon>
		<p>brands_fonticons_fi</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_fonticons"></malb-icon>
		<p>brands_fonticons</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_fort_awesome_alt"></malb-icon>
		<p>brands_fort_awesome_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_fort_awesome"></malb-icon>
		<p>brands_fort_awesome</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_forumbee"></malb-icon>
		<p>brands_forumbee</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_foursquare"></malb-icon>
		<p>brands_foursquare</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_free_code_camp"></malb-icon>
		<p>brands_free_code_camp</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_freebsd"></malb-icon>
		<p>brands_freebsd</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_fulcrum"></malb-icon>
		<p>brands_fulcrum</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_galactic_republic"></malb-icon>
		<p>brands_galactic_republic</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_galactic_senate"></malb-icon>
		<p>brands_galactic_senate</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_get_pocket"></malb-icon>
		<p>brands_get_pocket</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_gg_circle"></malb-icon>
		<p>brands_gg_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_gg"></malb-icon>
		<p>brands_gg</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_git_alt"></malb-icon>
		<p>brands_git_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_git_square"></malb-icon>
		<p>brands_git_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_git"></malb-icon>
		<p>brands_git</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_github_alt"></malb-icon>
		<p>brands_github_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_github_square"></malb-icon>
		<p>brands_github_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_github"></malb-icon>
		<p>brands_github</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_gitkraken"></malb-icon>
		<p>brands_gitkraken</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_gitlab"></malb-icon>
		<p>brands_gitlab</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_gitter"></malb-icon>
		<p>brands_gitter</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_glide_g"></malb-icon>
		<p>brands_glide_g</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_glide"></malb-icon>
		<p>brands_glide</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_gofore"></malb-icon>
		<p>brands_gofore</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_goodreads_g"></malb-icon>
		<p>brands_goodreads_g</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_goodreads"></malb-icon>
		<p>brands_goodreads</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_google_drive"></malb-icon>
		<p>brands_google_drive</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_google_pay"></malb-icon>
		<p>brands_google_pay</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_google_play"></malb-icon>
		<p>brands_google_play</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_google_plus_g"></malb-icon>
		<p>brands_google_plus_g</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_google_plus_square"></malb-icon>
		<p>brands_google_plus_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_google_plus"></malb-icon>
		<p>brands_google_plus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_google_wallet"></malb-icon>
		<p>brands_google_wallet</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_google"></malb-icon>
		<p>brands_google</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_gratipay"></malb-icon>
		<p>brands_gratipay</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_grav"></malb-icon>
		<p>brands_grav</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_gripfire"></malb-icon>
		<p>brands_gripfire</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_grunt"></malb-icon>
		<p>brands_grunt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_guilded"></malb-icon>
		<p>brands_guilded</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_gulp"></malb-icon>
		<p>brands_gulp</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_hacker_news_square"></malb-icon>
		<p>brands_hacker_news_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_hacker_news"></malb-icon>
		<p>brands_hacker_news</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_hackerrank"></malb-icon>
		<p>brands_hackerrank</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_hips"></malb-icon>
		<p>brands_hips</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_hire_a_helper"></malb-icon>
		<p>brands_hire_a_helper</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_hive"></malb-icon>
		<p>brands_hive</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_hooli"></malb-icon>
		<p>brands_hooli</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_hornbill"></malb-icon>
		<p>brands_hornbill</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_hotjar"></malb-icon>
		<p>brands_hotjar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_houzz"></malb-icon>
		<p>brands_houzz</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_html5"></malb-icon>
		<p>brands_html5</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_hubspot"></malb-icon>
		<p>brands_hubspot</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ideal"></malb-icon>
		<p>brands_ideal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_imdb"></malb-icon>
		<p>brands_imdb</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_innosoft"></malb-icon>
		<p>brands_innosoft</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_instagram_square"></malb-icon>
		<p>brands_instagram_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_instagram"></malb-icon>
		<p>brands_instagram</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_instalod"></malb-icon>
		<p>brands_instalod</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_intercom"></malb-icon>
		<p>brands_intercom</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_internet_explorer"></malb-icon>
		<p>brands_internet_explorer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_invision"></malb-icon>
		<p>brands_invision</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ioxhost"></malb-icon>
		<p>brands_ioxhost</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_itch_io"></malb-icon>
		<p>brands_itch_io</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_itunes_note"></malb-icon>
		<p>brands_itunes_note</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_itunes"></malb-icon>
		<p>brands_itunes</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_java"></malb-icon>
		<p>brands_java</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_jedi_order"></malb-icon>
		<p>brands_jedi_order</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_jenkins"></malb-icon>
		<p>brands_jenkins</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_jira"></malb-icon>
		<p>brands_jira</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_joget"></malb-icon>
		<p>brands_joget</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_joomla"></malb-icon>
		<p>brands_joomla</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_js_square"></malb-icon>
		<p>brands_js_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_js"></malb-icon>
		<p>brands_js</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_jsfiddle"></malb-icon>
		<p>brands_jsfiddle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_kaggle"></malb-icon>
		<p>brands_kaggle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_keybase"></malb-icon>
		<p>brands_keybase</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_keycdn"></malb-icon>
		<p>brands_keycdn</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_kickstarter_k"></malb-icon>
		<p>brands_kickstarter_k</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_kickstarter"></malb-icon>
		<p>brands_kickstarter</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_korvue"></malb-icon>
		<p>brands_korvue</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_laravel"></malb-icon>
		<p>brands_laravel</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_lastfm_square"></malb-icon>
		<p>brands_lastfm_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_lastfm"></malb-icon>
		<p>brands_lastfm</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_leanpub"></malb-icon>
		<p>brands_leanpub</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_less"></malb-icon>
		<p>brands_less</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_line"></malb-icon>
		<p>brands_line</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_linkedin_in"></malb-icon>
		<p>brands_linkedin_in</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_linkedin"></malb-icon>
		<p>brands_linkedin</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_linode"></malb-icon>
		<p>brands_linode</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_linux"></malb-icon>
		<p>brands_linux</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_lyft"></malb-icon>
		<p>brands_lyft</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_magento"></malb-icon>
		<p>brands_magento</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_mailchimp"></malb-icon>
		<p>brands_mailchimp</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_mandalorian"></malb-icon>
		<p>brands_mandalorian</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_markdown"></malb-icon>
		<p>brands_markdown</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_mastodon"></malb-icon>
		<p>brands_mastodon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_maxcdn"></malb-icon>
		<p>brands_maxcdn</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_mdb"></malb-icon>
		<p>brands_mdb</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_medapps"></malb-icon>
		<p>brands_medapps</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_medium_m"></malb-icon>
		<p>brands_medium_m</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_medium"></malb-icon>
		<p>brands_medium</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_medrt"></malb-icon>
		<p>brands_medrt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_meetup"></malb-icon>
		<p>brands_meetup</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_megaport"></malb-icon>
		<p>brands_megaport</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_mendeley"></malb-icon>
		<p>brands_mendeley</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_microblog"></malb-icon>
		<p>brands_microblog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_microsoft"></malb-icon>
		<p>brands_microsoft</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_mix"></malb-icon>
		<p>brands_mix</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_mixcloud"></malb-icon>
		<p>brands_mixcloud</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_mixer"></malb-icon>
		<p>brands_mixer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_mizuni"></malb-icon>
		<p>brands_mizuni</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_modx"></malb-icon>
		<p>brands_modx</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_monero"></malb-icon>
		<p>brands_monero</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_napster"></malb-icon>
		<p>brands_napster</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_neos"></malb-icon>
		<p>brands_neos</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_nimblr"></malb-icon>
		<p>brands_nimblr</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_node_js"></malb-icon>
		<p>brands_node_js</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_node"></malb-icon>
		<p>brands_node</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_npm"></malb-icon>
		<p>brands_npm</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ns8"></malb-icon>
		<p>brands_ns8</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_nutritionix"></malb-icon>
		<p>brands_nutritionix</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_octopus_deploy"></malb-icon>
		<p>brands_octopus_deploy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_odnoklassniki_square"></malb-icon>
		<p>brands_odnoklassniki_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_odnoklassniki"></malb-icon>
		<p>brands_odnoklassniki</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_old_republic"></malb-icon>
		<p>brands_old_republic</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_opencart"></malb-icon>
		<p>brands_opencart</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_openid"></malb-icon>
		<p>brands_openid</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_opera"></malb-icon>
		<p>brands_opera</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_optin_monster"></malb-icon>
		<p>brands_optin_monster</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_orcid"></malb-icon>
		<p>brands_orcid</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_osi"></malb-icon>
		<p>brands_osi</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_page4"></malb-icon>
		<p>brands_page4</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_pagelines"></malb-icon>
		<p>brands_pagelines</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_palfed"></malb-icon>
		<p>brands_palfed</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_patreon"></malb-icon>
		<p>brands_patreon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_paypal"></malb-icon>
		<p>brands_paypal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_penny_arcade"></malb-icon>
		<p>brands_penny_arcade</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_perbyte"></malb-icon>
		<p>brands_perbyte</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_periscope"></malb-icon>
		<p>brands_periscope</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_phabricator"></malb-icon>
		<p>brands_phabricator</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_phoenix_framework"></malb-icon>
		<p>brands_phoenix_framework</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_phoenix_squadron"></malb-icon>
		<p>brands_phoenix_squadron</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_php"></malb-icon>
		<p>brands_php</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_pied_piper_alt"></malb-icon>
		<p>brands_pied_piper_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_pied_piper_hat"></malb-icon>
		<p>brands_pied_piper_hat</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_pied_piper_pp"></malb-icon>
		<p>brands_pied_piper_pp</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_pied_piper_square"></malb-icon>
		<p>brands_pied_piper_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_pied_piper"></malb-icon>
		<p>brands_pied_piper</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_pinterest_p"></malb-icon>
		<p>brands_pinterest_p</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_pinterest_square"></malb-icon>
		<p>brands_pinterest_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_pinterest"></malb-icon>
		<p>brands_pinterest</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_playstation"></malb-icon>
		<p>brands_playstation</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_product_hunt"></malb-icon>
		<p>brands_product_hunt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_pushed"></malb-icon>
		<p>brands_pushed</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_python"></malb-icon>
		<p>brands_python</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_qq"></malb-icon>
		<p>brands_qq</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_quinscape"></malb-icon>
		<p>brands_quinscape</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_quora"></malb-icon>
		<p>brands_quora</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_r_project"></malb-icon>
		<p>brands_r_project</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_raspberry_pi"></malb-icon>
		<p>brands_raspberry_pi</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ravelry"></malb-icon>
		<p>brands_ravelry</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_react"></malb-icon>
		<p>brands_react</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_reacteurope"></malb-icon>
		<p>brands_reacteurope</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_readme"></malb-icon>
		<p>brands_readme</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_rebel"></malb-icon>
		<p>brands_rebel</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_red_river"></malb-icon>
		<p>brands_red_river</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_reddit_alien"></malb-icon>
		<p>brands_reddit_alien</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_reddit_square"></malb-icon>
		<p>brands_reddit_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_reddit"></malb-icon>
		<p>brands_reddit</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_redhat"></malb-icon>
		<p>brands_redhat</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_renren"></malb-icon>
		<p>brands_renren</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_replyd"></malb-icon>
		<p>brands_replyd</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_researchgate"></malb-icon>
		<p>brands_researchgate</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_resolving"></malb-icon>
		<p>brands_resolving</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_rev"></malb-icon>
		<p>brands_rev</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_rocketchat"></malb-icon>
		<p>brands_rocketchat</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_rockrms"></malb-icon>
		<p>brands_rockrms</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_rust"></malb-icon>
		<p>brands_rust</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_safari"></malb-icon>
		<p>brands_safari</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_salesforce"></malb-icon>
		<p>brands_salesforce</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_sass"></malb-icon>
		<p>brands_sass</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_schlix"></malb-icon>
		<p>brands_schlix</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_scribd"></malb-icon>
		<p>brands_scribd</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_searchengin"></malb-icon>
		<p>brands_searchengin</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_sellcast"></malb-icon>
		<p>brands_sellcast</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_sellsy"></malb-icon>
		<p>brands_sellsy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_servicestack"></malb-icon>
		<p>brands_servicestack</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_shirtsinbulk"></malb-icon>
		<p>brands_shirtsinbulk</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_shopify"></malb-icon>
		<p>brands_shopify</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_shopware"></malb-icon>
		<p>brands_shopware</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_simplybuilt"></malb-icon>
		<p>brands_simplybuilt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_sistrix"></malb-icon>
		<p>brands_sistrix</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_sith"></malb-icon>
		<p>brands_sith</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_sketch"></malb-icon>
		<p>brands_sketch</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_skyatlas"></malb-icon>
		<p>brands_skyatlas</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_skype"></malb-icon>
		<p>brands_skype</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_slack_hash"></malb-icon>
		<p>brands_slack_hash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_slack"></malb-icon>
		<p>brands_slack</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_slideshare"></malb-icon>
		<p>brands_slideshare</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_snapchat_ghost"></malb-icon>
		<p>brands_snapchat_ghost</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_snapchat_square"></malb-icon>
		<p>brands_snapchat_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_snapchat"></malb-icon>
		<p>brands_snapchat</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_soundcloud"></malb-icon>
		<p>brands_soundcloud</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_sourcetree"></malb-icon>
		<p>brands_sourcetree</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_speakap"></malb-icon>
		<p>brands_speakap</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_speaker_deck"></malb-icon>
		<p>brands_speaker_deck</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_spotify"></malb-icon>
		<p>brands_spotify</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_squarespace"></malb-icon>
		<p>brands_squarespace</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_stack_exchange"></malb-icon>
		<p>brands_stack_exchange</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_stack_overflow"></malb-icon>
		<p>brands_stack_overflow</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_stackpath"></malb-icon>
		<p>brands_stackpath</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_staylinked"></malb-icon>
		<p>brands_staylinked</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_steam_square"></malb-icon>
		<p>brands_steam_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_steam_symbol"></malb-icon>
		<p>brands_steam_symbol</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_steam"></malb-icon>
		<p>brands_steam</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_sticker_mule"></malb-icon>
		<p>brands_sticker_mule</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_strava"></malb-icon>
		<p>brands_strava</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_stripe_s"></malb-icon>
		<p>brands_stripe_s</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_stripe"></malb-icon>
		<p>brands_stripe</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_studiovinari"></malb-icon>
		<p>brands_studiovinari</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_stumbleupon_circle"></malb-icon>
		<p>brands_stumbleupon_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_stumbleupon"></malb-icon>
		<p>brands_stumbleupon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_superpowers"></malb-icon>
		<p>brands_superpowers</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_supple"></malb-icon>
		<p>brands_supple</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_suse"></malb-icon>
		<p>brands_suse</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_swift"></malb-icon>
		<p>brands_swift</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_symfony"></malb-icon>
		<p>brands_symfony</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_teamspeak"></malb-icon>
		<p>brands_teamspeak</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_telegram_plane"></malb-icon>
		<p>brands_telegram_plane</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_telegram"></malb-icon>
		<p>brands_telegram</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_tencent_weibo"></malb-icon>
		<p>brands_tencent_weibo</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_the_red_yeti"></malb-icon>
		<p>brands_the_red_yeti</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_themeco"></malb-icon>
		<p>brands_themeco</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_themeisle"></malb-icon>
		<p>brands_themeisle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_think_peaks"></malb-icon>
		<p>brands_think_peaks</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_tiktok"></malb-icon>
		<p>brands_tiktok</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_trade_federation"></malb-icon>
		<p>brands_trade_federation</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_trello"></malb-icon>
		<p>brands_trello</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_tripadvisor"></malb-icon>
		<p>brands_tripadvisor</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_tumblr_square"></malb-icon>
		<p>brands_tumblr_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_tumblr"></malb-icon>
		<p>brands_tumblr</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_twitch"></malb-icon>
		<p>brands_twitch</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_twitter_square"></malb-icon>
		<p>brands_twitter_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_twitter"></malb-icon>
		<p>brands_twitter</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_typo3"></malb-icon>
		<p>brands_typo3</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_uber"></malb-icon>
		<p>brands_uber</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ubuntu"></malb-icon>
		<p>brands_ubuntu</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_uikit"></malb-icon>
		<p>brands_uikit</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_umbraco"></malb-icon>
		<p>brands_umbraco</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_uncharted"></malb-icon>
		<p>brands_uncharted</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_uniregistry"></malb-icon>
		<p>brands_uniregistry</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_unity"></malb-icon>
		<p>brands_unity</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_unsplash"></malb-icon>
		<p>brands_unsplash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_untappd"></malb-icon>
		<p>brands_untappd</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ups"></malb-icon>
		<p>brands_ups</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_usb"></malb-icon>
		<p>brands_usb</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_usps"></malb-icon>
		<p>brands_usps</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_ussunnah"></malb-icon>
		<p>brands_ussunnah</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_vaadin"></malb-icon>
		<p>brands_vaadin</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_viacoin"></malb-icon>
		<p>brands_viacoin</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_viadeo_square"></malb-icon>
		<p>brands_viadeo_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_viadeo"></malb-icon>
		<p>brands_viadeo</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_viber"></malb-icon>
		<p>brands_viber</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_vimeo_square"></malb-icon>
		<p>brands_vimeo_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_vimeo_v"></malb-icon>
		<p>brands_vimeo_v</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_vimeo"></malb-icon>
		<p>brands_vimeo</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_vine"></malb-icon>
		<p>brands_vine</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_vk"></malb-icon>
		<p>brands_vk</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_vnv"></malb-icon>
		<p>brands_vnv</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_vuejs"></malb-icon>
		<p>brands_vuejs</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_watchman_monitoring"></malb-icon>
		<p>brands_watchman_monitoring</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_waze"></malb-icon>
		<p>brands_waze</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_weebly"></malb-icon>
		<p>brands_weebly</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_weibo"></malb-icon>
		<p>brands_weibo</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_weixin"></malb-icon>
		<p>brands_weixin</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_whatsapp_square"></malb-icon>
		<p>brands_whatsapp_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_whatsapp"></malb-icon>
		<p>brands_whatsapp</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_whmcs"></malb-icon>
		<p>brands_whmcs</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wikipedia_w"></malb-icon>
		<p>brands_wikipedia_w</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_windows"></malb-icon>
		<p>brands_windows</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wix"></malb-icon>
		<p>brands_wix</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wizards_of_the_coast"></malb-icon>
		<p>brands_wizards_of_the_coast</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wodu"></malb-icon>
		<p>brands_wodu</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wolf_pack_battalion"></malb-icon>
		<p>brands_wolf_pack_battalion</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wordpress_simple"></malb-icon>
		<p>brands_wordpress_simple</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wordpress"></malb-icon>
		<p>brands_wordpress</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wpbeginner"></malb-icon>
		<p>brands_wpbeginner</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wpexplorer"></malb-icon>
		<p>brands_wpexplorer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wpforms"></malb-icon>
		<p>brands_wpforms</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_wpressr"></malb-icon>
		<p>brands_wpressr</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_xbox"></malb-icon>
		<p>brands_xbox</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_xing_square"></malb-icon>
		<p>brands_xing_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_xing"></malb-icon>
		<p>brands_xing</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_y_combinator"></malb-icon>
		<p>brands_y_combinator</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_yahoo"></malb-icon>
		<p>brands_yahoo</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_yammer"></malb-icon>
		<p>brands_yammer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_yandex_international"></malb-icon>
		<p>brands_yandex_international</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_yandex"></malb-icon>
		<p>brands_yandex</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_yarn"></malb-icon>
		<p>brands_yarn</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_yelp"></malb-icon>
		<p>brands_yelp</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_yoast"></malb-icon>
		<p>brands_yoast</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_youtube_square"></malb-icon>
		<p>brands_youtube_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_youtube"></malb-icon>
		<p>brands_youtube</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="brands_zhihu"></malb-icon>
		<p>brands_zhihu</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_address_book"></malb-icon>
		<p>regular_address_book</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_address_card"></malb-icon>
		<p>regular_address_card</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_angry"></malb-icon>
		<p>regular_angry</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_arrow_alt_circle_down"></malb-icon>
		<p>regular_arrow_alt_circle_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_arrow_alt_circle_left"></malb-icon>
		<p>regular_arrow_alt_circle_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_arrow_alt_circle_right"></malb-icon>
		<p>regular_arrow_alt_circle_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_arrow_alt_circle_up"></malb-icon>
		<p>regular_arrow_alt_circle_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_bell_slash"></malb-icon>
		<p>regular_bell_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_bell"></malb-icon>
		<p>regular_bell</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_bookmark"></malb-icon>
		<p>regular_bookmark</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_building"></malb-icon>
		<p>regular_building</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_calendar_alt"></malb-icon>
		<p>regular_calendar_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_calendar_check"></malb-icon>
		<p>regular_calendar_check</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_calendar_minus"></malb-icon>
		<p>regular_calendar_minus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_calendar_plus"></malb-icon>
		<p>regular_calendar_plus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_calendar_times"></malb-icon>
		<p>regular_calendar_times</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_calendar"></malb-icon>
		<p>regular_calendar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_caret_square_down"></malb-icon>
		<p>regular_caret_square_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_caret_square_left"></malb-icon>
		<p>regular_caret_square_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_caret_square_right"></malb-icon>
		<p>regular_caret_square_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_caret_square_up"></malb-icon>
		<p>regular_caret_square_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_chart_bar"></malb-icon>
		<p>regular_chart_bar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_check_circle"></malb-icon>
		<p>regular_check_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_check_square"></malb-icon>
		<p>regular_check_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_circle"></malb-icon>
		<p>regular_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_clipboard"></malb-icon>
		<p>regular_clipboard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_clock"></malb-icon>
		<p>regular_clock</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_clone"></malb-icon>
		<p>regular_clone</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_closed_captioning"></malb-icon>
		<p>regular_closed_captioning</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_comment_alt"></malb-icon>
		<p>regular_comment_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_comment_dots"></malb-icon>
		<p>regular_comment_dots</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_comment"></malb-icon>
		<p>regular_comment</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_comments"></malb-icon>
		<p>regular_comments</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_compass"></malb-icon>
		<p>regular_compass</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_copy"></malb-icon>
		<p>regular_copy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_copyright"></malb-icon>
		<p>regular_copyright</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_credit_card"></malb-icon>
		<p>regular_credit_card</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_dizzy"></malb-icon>
		<p>regular_dizzy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_dot_circle"></malb-icon>
		<p>regular_dot_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_edit"></malb-icon>
		<p>regular_edit</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_envelope_open"></malb-icon>
		<p>regular_envelope_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_envelope"></malb-icon>
		<p>regular_envelope</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_eye_slash"></malb-icon>
		<p>regular_eye_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_eye"></malb-icon>
		<p>regular_eye</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file_alt"></malb-icon>
		<p>regular_file_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file_archive"></malb-icon>
		<p>regular_file_archive</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file_audio"></malb-icon>
		<p>regular_file_audio</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file_code"></malb-icon>
		<p>regular_file_code</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file_excel"></malb-icon>
		<p>regular_file_excel</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file_image"></malb-icon>
		<p>regular_file_image</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file_pdf"></malb-icon>
		<p>regular_file_pdf</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file_powerpoint"></malb-icon>
		<p>regular_file_powerpoint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file_video"></malb-icon>
		<p>regular_file_video</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file_word"></malb-icon>
		<p>regular_file_word</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_file"></malb-icon>
		<p>regular_file</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_flag"></malb-icon>
		<p>regular_flag</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_flushed"></malb-icon>
		<p>regular_flushed</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_folder_open"></malb-icon>
		<p>regular_folder_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_folder"></malb-icon>
		<p>regular_folder</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_font_awesome_logo_full"></malb-icon>
		<p>regular_font_awesome_logo_full</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_frown_open"></malb-icon>
		<p>regular_frown_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_frown"></malb-icon>
		<p>regular_frown</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_futbol"></malb-icon>
		<p>regular_futbol</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_gem"></malb-icon>
		<p>regular_gem</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grimace"></malb-icon>
		<p>regular_grimace</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_alt"></malb-icon>
		<p>regular_grin_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_beam_sweat"></malb-icon>
		<p>regular_grin_beam_sweat</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_beam"></malb-icon>
		<p>regular_grin_beam</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_hearts"></malb-icon>
		<p>regular_grin_hearts</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_squint_tears"></malb-icon>
		<p>regular_grin_squint_tears</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_squint"></malb-icon>
		<p>regular_grin_squint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_stars"></malb-icon>
		<p>regular_grin_stars</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_tears"></malb-icon>
		<p>regular_grin_tears</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_tongue_squint"></malb-icon>
		<p>regular_grin_tongue_squint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_tongue_wink"></malb-icon>
		<p>regular_grin_tongue_wink</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_tongue"></malb-icon>
		<p>regular_grin_tongue</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin_wink"></malb-icon>
		<p>regular_grin_wink</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_grin"></malb-icon>
		<p>regular_grin</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_lizard"></malb-icon>
		<p>regular_hand_lizard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_paper"></malb-icon>
		<p>regular_hand_paper</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_peace"></malb-icon>
		<p>regular_hand_peace</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_point_down"></malb-icon>
		<p>regular_hand_point_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_point_left"></malb-icon>
		<p>regular_hand_point_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_point_right"></malb-icon>
		<p>regular_hand_point_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_point_up"></malb-icon>
		<p>regular_hand_point_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_pointer"></malb-icon>
		<p>regular_hand_pointer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_rock"></malb-icon>
		<p>regular_hand_rock</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_scissors"></malb-icon>
		<p>regular_hand_scissors</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hand_spock"></malb-icon>
		<p>regular_hand_spock</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_handshake"></malb-icon>
		<p>regular_handshake</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hdd"></malb-icon>
		<p>regular_hdd</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_heart"></malb-icon>
		<p>regular_heart</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hospital"></malb-icon>
		<p>regular_hospital</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_hourglass"></malb-icon>
		<p>regular_hourglass</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_id_badge"></malb-icon>
		<p>regular_id_badge</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_id_card"></malb-icon>
		<p>regular_id_card</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_image"></malb-icon>
		<p>regular_image</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_images"></malb-icon>
		<p>regular_images</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_keyboard"></malb-icon>
		<p>regular_keyboard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_kiss_beam"></malb-icon>
		<p>regular_kiss_beam</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_kiss_wink_heart"></malb-icon>
		<p>regular_kiss_wink_heart</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_kiss"></malb-icon>
		<p>regular_kiss</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_laugh_beam"></malb-icon>
		<p>regular_laugh_beam</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_laugh_squint"></malb-icon>
		<p>regular_laugh_squint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_laugh_wink"></malb-icon>
		<p>regular_laugh_wink</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_laugh"></malb-icon>
		<p>regular_laugh</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_lemon"></malb-icon>
		<p>regular_lemon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_life_ring"></malb-icon>
		<p>regular_life_ring</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_lightbulb"></malb-icon>
		<p>regular_lightbulb</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_list_alt"></malb-icon>
		<p>regular_list_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_map"></malb-icon>
		<p>regular_map</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_meh_blank"></malb-icon>
		<p>regular_meh_blank</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_meh_rolling_eyes"></malb-icon>
		<p>regular_meh_rolling_eyes</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_meh"></malb-icon>
		<p>regular_meh</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_minus_square"></malb-icon>
		<p>regular_minus_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_money_bill_alt"></malb-icon>
		<p>regular_money_bill_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_moon"></malb-icon>
		<p>regular_moon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_newspaper"></malb-icon>
		<p>regular_newspaper</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_object_group"></malb-icon>
		<p>regular_object_group</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_object_ungroup"></malb-icon>
		<p>regular_object_ungroup</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_paper_plane"></malb-icon>
		<p>regular_paper_plane</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_pause_circle"></malb-icon>
		<p>regular_pause_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_play_circle"></malb-icon>
		<p>regular_play_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_plus_square"></malb-icon>
		<p>regular_plus_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_question_circle"></malb-icon>
		<p>regular_question_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_registered"></malb-icon>
		<p>regular_registered</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_sad_cry"></malb-icon>
		<p>regular_sad_cry</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_sad_tear"></malb-icon>
		<p>regular_sad_tear</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_save"></malb-icon>
		<p>regular_save</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_share_square"></malb-icon>
		<p>regular_share_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_smile_beam"></malb-icon>
		<p>regular_smile_beam</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_smile_wink"></malb-icon>
		<p>regular_smile_wink</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_smile"></malb-icon>
		<p>regular_smile</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_snowflake"></malb-icon>
		<p>regular_snowflake</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_square"></malb-icon>
		<p>regular_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_star_half"></malb-icon>
		<p>regular_star_half</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_star"></malb-icon>
		<p>regular_star</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_sticky_note"></malb-icon>
		<p>regular_sticky_note</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_stop_circle"></malb-icon>
		<p>regular_stop_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_sun"></malb-icon>
		<p>regular_sun</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_surprise"></malb-icon>
		<p>regular_surprise</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_thumbs_down"></malb-icon>
		<p>regular_thumbs_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_thumbs_up"></malb-icon>
		<p>regular_thumbs_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_times_circle"></malb-icon>
		<p>regular_times_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_tired"></malb-icon>
		<p>regular_tired</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_trash_alt"></malb-icon>
		<p>regular_trash_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_user_circle"></malb-icon>
		<p>regular_user_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_user"></malb-icon>
		<p>regular_user</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_window_close"></malb-icon>
		<p>regular_window_close</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_window_maximize"></malb-icon>
		<p>regular_window_maximize</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_window_minimize"></malb-icon>
		<p>regular_window_minimize</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="regular_window_restore"></malb-icon>
		<p>regular_window_restore</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ad"></malb-icon>
		<p>solid_ad</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_address_book"></malb-icon>
		<p>solid_address_book</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_address_card"></malb-icon>
		<p>solid_address_card</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_adjust"></malb-icon>
		<p>solid_adjust</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_air_freshener"></malb-icon>
		<p>solid_air_freshener</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_align_center"></malb-icon>
		<p>solid_align_center</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_align_justify"></malb-icon>
		<p>solid_align_justify</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_align_left"></malb-icon>
		<p>solid_align_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_align_right"></malb-icon>
		<p>solid_align_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_allergies"></malb-icon>
		<p>solid_allergies</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ambulance"></malb-icon>
		<p>solid_ambulance</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_american_sign_language_interpreting"></malb-icon>
		<p>solid_american_sign_language_interpreting</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_anchor"></malb-icon>
		<p>solid_anchor</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_angle_double_down"></malb-icon>
		<p>solid_angle_double_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_angle_double_left"></malb-icon>
		<p>solid_angle_double_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_angle_double_right"></malb-icon>
		<p>solid_angle_double_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_angle_double_up"></malb-icon>
		<p>solid_angle_double_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_angle_down"></malb-icon>
		<p>solid_angle_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_angle_left"></malb-icon>
		<p>solid_angle_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_angle_right"></malb-icon>
		<p>solid_angle_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_angle_up"></malb-icon>
		<p>solid_angle_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_angry"></malb-icon>
		<p>solid_angry</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ankh"></malb-icon>
		<p>solid_ankh</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_apple_alt"></malb-icon>
		<p>solid_apple_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_archive"></malb-icon>
		<p>solid_archive</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_archway"></malb-icon>
		<p>solid_archway</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_alt_circle_down"></malb-icon>
		<p>solid_arrow_alt_circle_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_alt_circle_left"></malb-icon>
		<p>solid_arrow_alt_circle_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_alt_circle_right"></malb-icon>
		<p>solid_arrow_alt_circle_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_alt_circle_up"></malb-icon>
		<p>solid_arrow_alt_circle_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_circle_down"></malb-icon>
		<p>solid_arrow_circle_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_circle_left"></malb-icon>
		<p>solid_arrow_circle_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_circle_right"></malb-icon>
		<p>solid_arrow_circle_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_circle_up"></malb-icon>
		<p>solid_arrow_circle_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_down"></malb-icon>
		<p>solid_arrow_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_left"></malb-icon>
		<p>solid_arrow_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_right"></malb-icon>
		<p>solid_arrow_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrow_up"></malb-icon>
		<p>solid_arrow_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrows_alt_h"></malb-icon>
		<p>solid_arrows_alt_h</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrows_alt_v"></malb-icon>
		<p>solid_arrows_alt_v</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_arrows_alt"></malb-icon>
		<p>solid_arrows_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_assistive_listening_systems"></malb-icon>
		<p>solid_assistive_listening_systems</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_asterisk"></malb-icon>
		<p>solid_asterisk</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_at"></malb-icon>
		<p>solid_at</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_atlas"></malb-icon>
		<p>solid_atlas</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_atom"></malb-icon>
		<p>solid_atom</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_audio_description"></malb-icon>
		<p>solid_audio_description</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_award"></malb-icon>
		<p>solid_award</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_baby_carriage"></malb-icon>
		<p>solid_baby_carriage</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_baby"></malb-icon>
		<p>solid_baby</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_backspace"></malb-icon>
		<p>solid_backspace</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_backward"></malb-icon>
		<p>solid_backward</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bacon"></malb-icon>
		<p>solid_bacon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bacteria"></malb-icon>
		<p>solid_bacteria</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bacterium"></malb-icon>
		<p>solid_bacterium</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bahai"></malb-icon>
		<p>solid_bahai</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_balance_scale_left"></malb-icon>
		<p>solid_balance_scale_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_balance_scale_right"></malb-icon>
		<p>solid_balance_scale_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_balance_scale"></malb-icon>
		<p>solid_balance_scale</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ban"></malb-icon>
		<p>solid_ban</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_band_aid"></malb-icon>
		<p>solid_band_aid</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_barcode"></malb-icon>
		<p>solid_barcode</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bars"></malb-icon>
		<p>solid_bars</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_baseball_ball"></malb-icon>
		<p>solid_baseball_ball</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_basketball_ball"></malb-icon>
		<p>solid_basketball_ball</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bath"></malb-icon>
		<p>solid_bath</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_battery_empty"></malb-icon>
		<p>solid_battery_empty</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_battery_full"></malb-icon>
		<p>solid_battery_full</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_battery_half"></malb-icon>
		<p>solid_battery_half</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_battery_quarter"></malb-icon>
		<p>solid_battery_quarter</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_battery_three_quarters"></malb-icon>
		<p>solid_battery_three_quarters</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bed"></malb-icon>
		<p>solid_bed</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_beer"></malb-icon>
		<p>solid_beer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bell_slash"></malb-icon>
		<p>solid_bell_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bell"></malb-icon>
		<p>solid_bell</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bezier_curve"></malb-icon>
		<p>solid_bezier_curve</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bible"></malb-icon>
		<p>solid_bible</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bicycle"></malb-icon>
		<p>solid_bicycle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_biking"></malb-icon>
		<p>solid_biking</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_binoculars"></malb-icon>
		<p>solid_binoculars</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_biohazard"></malb-icon>
		<p>solid_biohazard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_birthday_cake"></malb-icon>
		<p>solid_birthday_cake</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_blender_phone"></malb-icon>
		<p>solid_blender_phone</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_blender"></malb-icon>
		<p>solid_blender</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_blind"></malb-icon>
		<p>solid_blind</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_blog"></malb-icon>
		<p>solid_blog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bold"></malb-icon>
		<p>solid_bold</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bolt"></malb-icon>
		<p>solid_bolt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bomb"></malb-icon>
		<p>solid_bomb</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bone"></malb-icon>
		<p>solid_bone</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bong"></malb-icon>
		<p>solid_bong</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_book_dead"></malb-icon>
		<p>solid_book_dead</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_book_medical"></malb-icon>
		<p>solid_book_medical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_book_open"></malb-icon>
		<p>solid_book_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_book_reader"></malb-icon>
		<p>solid_book_reader</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_book"></malb-icon>
		<p>solid_book</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bookmark"></malb-icon>
		<p>solid_bookmark</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_border_all"></malb-icon>
		<p>solid_border_all</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_border_none"></malb-icon>
		<p>solid_border_none</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_border_style"></malb-icon>
		<p>solid_border_style</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bowling_ball"></malb-icon>
		<p>solid_bowling_ball</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_box_open"></malb-icon>
		<p>solid_box_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_box_tissue"></malb-icon>
		<p>solid_box_tissue</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_box"></malb-icon>
		<p>solid_box</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_boxes"></malb-icon>
		<p>solid_boxes</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_braille"></malb-icon>
		<p>solid_braille</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_brain"></malb-icon>
		<p>solid_brain</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bread_slice"></malb-icon>
		<p>solid_bread_slice</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_briefcase_medical"></malb-icon>
		<p>solid_briefcase_medical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_briefcase"></malb-icon>
		<p>solid_briefcase</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_broadcast_tower"></malb-icon>
		<p>solid_broadcast_tower</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_broom"></malb-icon>
		<p>solid_broom</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_brush"></malb-icon>
		<p>solid_brush</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bug"></malb-icon>
		<p>solid_bug</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_building"></malb-icon>
		<p>solid_building</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bullhorn"></malb-icon>
		<p>solid_bullhorn</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bullseye"></malb-icon>
		<p>solid_bullseye</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_burn"></malb-icon>
		<p>solid_burn</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bus_alt"></malb-icon>
		<p>solid_bus_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_bus"></malb-icon>
		<p>solid_bus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_business_time"></malb-icon>
		<p>solid_business_time</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_calculator"></malb-icon>
		<p>solid_calculator</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_calendar_alt"></malb-icon>
		<p>solid_calendar_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_calendar_check"></malb-icon>
		<p>solid_calendar_check</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_calendar_day"></malb-icon>
		<p>solid_calendar_day</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_calendar_minus"></malb-icon>
		<p>solid_calendar_minus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_calendar_plus"></malb-icon>
		<p>solid_calendar_plus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_calendar_times"></malb-icon>
		<p>solid_calendar_times</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_calendar_week"></malb-icon>
		<p>solid_calendar_week</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_calendar"></malb-icon>
		<p>solid_calendar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_camera_retro"></malb-icon>
		<p>solid_camera_retro</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_camera"></malb-icon>
		<p>solid_camera</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_campground"></malb-icon>
		<p>solid_campground</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_candy_cane"></malb-icon>
		<p>solid_candy_cane</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cannabis"></malb-icon>
		<p>solid_cannabis</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_capsules"></malb-icon>
		<p>solid_capsules</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_car_alt"></malb-icon>
		<p>solid_car_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_car_battery"></malb-icon>
		<p>solid_car_battery</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_car_crash"></malb-icon>
		<p>solid_car_crash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_car_side"></malb-icon>
		<p>solid_car_side</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_car"></malb-icon>
		<p>solid_car</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_caravan"></malb-icon>
		<p>solid_caravan</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_caret_down"></malb-icon>
		<p>solid_caret_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_caret_left"></malb-icon>
		<p>solid_caret_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_caret_right"></malb-icon>
		<p>solid_caret_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_caret_square_down"></malb-icon>
		<p>solid_caret_square_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_caret_square_left"></malb-icon>
		<p>solid_caret_square_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_caret_square_right"></malb-icon>
		<p>solid_caret_square_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_caret_square_up"></malb-icon>
		<p>solid_caret_square_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_caret_up"></malb-icon>
		<p>solid_caret_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_carrot"></malb-icon>
		<p>solid_carrot</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cart_arrow_down"></malb-icon>
		<p>solid_cart_arrow_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cart_plus"></malb-icon>
		<p>solid_cart_plus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cash_register"></malb-icon>
		<p>solid_cash_register</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cat"></malb-icon>
		<p>solid_cat</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_certificate"></malb-icon>
		<p>solid_certificate</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chair"></malb-icon>
		<p>solid_chair</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chalkboard_teacher"></malb-icon>
		<p>solid_chalkboard_teacher</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chalkboard"></malb-icon>
		<p>solid_chalkboard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_charging_station"></malb-icon>
		<p>solid_charging_station</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chart_area"></malb-icon>
		<p>solid_chart_area</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chart_bar"></malb-icon>
		<p>solid_chart_bar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chart_line"></malb-icon>
		<p>solid_chart_line</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chart_pie"></malb-icon>
		<p>solid_chart_pie</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_check_circle"></malb-icon>
		<p>solid_check_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_check_double"></malb-icon>
		<p>solid_check_double</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_check_square"></malb-icon>
		<p>solid_check_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_check"></malb-icon>
		<p>solid_check</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cheese"></malb-icon>
		<p>solid_cheese</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chess_bishop"></malb-icon>
		<p>solid_chess_bishop</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chess_board"></malb-icon>
		<p>solid_chess_board</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chess_king"></malb-icon>
		<p>solid_chess_king</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chess_knight"></malb-icon>
		<p>solid_chess_knight</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chess_pawn"></malb-icon>
		<p>solid_chess_pawn</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chess_queen"></malb-icon>
		<p>solid_chess_queen</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chess_rook"></malb-icon>
		<p>solid_chess_rook</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chess"></malb-icon>
		<p>solid_chess</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chevron_circle_down"></malb-icon>
		<p>solid_chevron_circle_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chevron_circle_left"></malb-icon>
		<p>solid_chevron_circle_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chevron_circle_right"></malb-icon>
		<p>solid_chevron_circle_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chevron_circle_up"></malb-icon>
		<p>solid_chevron_circle_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chevron_down"></malb-icon>
		<p>solid_chevron_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chevron_left"></malb-icon>
		<p>solid_chevron_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chevron_right"></malb-icon>
		<p>solid_chevron_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_chevron_up"></malb-icon>
		<p>solid_chevron_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_child"></malb-icon>
		<p>solid_child</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_church"></malb-icon>
		<p>solid_church</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_circle_notch"></malb-icon>
		<p>solid_circle_notch</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_circle"></malb-icon>
		<p>solid_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_city"></malb-icon>
		<p>solid_city</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_clinic_medical"></malb-icon>
		<p>solid_clinic_medical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_clipboard_check"></malb-icon>
		<p>solid_clipboard_check</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_clipboard_list"></malb-icon>
		<p>solid_clipboard_list</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_clipboard"></malb-icon>
		<p>solid_clipboard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_clock"></malb-icon>
		<p>solid_clock</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_clone"></malb-icon>
		<p>solid_clone</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_closed_captioning"></malb-icon>
		<p>solid_closed_captioning</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cloud_download_alt"></malb-icon>
		<p>solid_cloud_download_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cloud_meatball"></malb-icon>
		<p>solid_cloud_meatball</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cloud_moon_rain"></malb-icon>
		<p>solid_cloud_moon_rain</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cloud_moon"></malb-icon>
		<p>solid_cloud_moon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cloud_rain"></malb-icon>
		<p>solid_cloud_rain</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cloud_showers_heavy"></malb-icon>
		<p>solid_cloud_showers_heavy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cloud_sun_rain"></malb-icon>
		<p>solid_cloud_sun_rain</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cloud_sun"></malb-icon>
		<p>solid_cloud_sun</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cloud_upload_alt"></malb-icon>
		<p>solid_cloud_upload_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cloud"></malb-icon>
		<p>solid_cloud</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cocktail"></malb-icon>
		<p>solid_cocktail</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_code_branch"></malb-icon>
		<p>solid_code_branch</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_code"></malb-icon>
		<p>solid_code</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_coffee"></malb-icon>
		<p>solid_coffee</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cog"></malb-icon>
		<p>solid_cog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cogs"></malb-icon>
		<p>solid_cogs</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_coins"></malb-icon>
		<p>solid_coins</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_columns"></malb-icon>
		<p>solid_columns</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_comment_alt"></malb-icon>
		<p>solid_comment_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_comment_dollar"></malb-icon>
		<p>solid_comment_dollar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_comment_dots"></malb-icon>
		<p>solid_comment_dots</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_comment_medical"></malb-icon>
		<p>solid_comment_medical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_comment_slash"></malb-icon>
		<p>solid_comment_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_comment"></malb-icon>
		<p>solid_comment</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_comments_dollar"></malb-icon>
		<p>solid_comments_dollar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_comments"></malb-icon>
		<p>solid_comments</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_compact_disc"></malb-icon>
		<p>solid_compact_disc</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_compass"></malb-icon>
		<p>solid_compass</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_compress_alt"></malb-icon>
		<p>solid_compress_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_compress_arrows_alt"></malb-icon>
		<p>solid_compress_arrows_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_compress"></malb-icon>
		<p>solid_compress</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_concierge_bell"></malb-icon>
		<p>solid_concierge_bell</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cookie_bite"></malb-icon>
		<p>solid_cookie_bite</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cookie"></malb-icon>
		<p>solid_cookie</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_copy"></malb-icon>
		<p>solid_copy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_copyright"></malb-icon>
		<p>solid_copyright</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_couch"></malb-icon>
		<p>solid_couch</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_credit_card"></malb-icon>
		<p>solid_credit_card</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_crop_alt"></malb-icon>
		<p>solid_crop_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_crop"></malb-icon>
		<p>solid_crop</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cross"></malb-icon>
		<p>solid_cross</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_crosshairs"></malb-icon>
		<p>solid_crosshairs</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_crow"></malb-icon>
		<p>solid_crow</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_crown"></malb-icon>
		<p>solid_crown</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_crutch"></malb-icon>
		<p>solid_crutch</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cube"></malb-icon>
		<p>solid_cube</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cubes"></malb-icon>
		<p>solid_cubes</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_cut"></malb-icon>
		<p>solid_cut</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_database"></malb-icon>
		<p>solid_database</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_deaf"></malb-icon>
		<p>solid_deaf</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_democrat"></malb-icon>
		<p>solid_democrat</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_desktop"></malb-icon>
		<p>solid_desktop</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dharmachakra"></malb-icon>
		<p>solid_dharmachakra</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_diagnoses"></malb-icon>
		<p>solid_diagnoses</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dice_d20"></malb-icon>
		<p>solid_dice_d20</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dice_d6"></malb-icon>
		<p>solid_dice_d6</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dice_five"></malb-icon>
		<p>solid_dice_five</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dice_four"></malb-icon>
		<p>solid_dice_four</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dice_one"></malb-icon>
		<p>solid_dice_one</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dice_six"></malb-icon>
		<p>solid_dice_six</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dice_three"></malb-icon>
		<p>solid_dice_three</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dice_two"></malb-icon>
		<p>solid_dice_two</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dice"></malb-icon>
		<p>solid_dice</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_digital_tachograph"></malb-icon>
		<p>solid_digital_tachograph</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_directions"></malb-icon>
		<p>solid_directions</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_disease"></malb-icon>
		<p>solid_disease</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_divide"></malb-icon>
		<p>solid_divide</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dizzy"></malb-icon>
		<p>solid_dizzy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dna"></malb-icon>
		<p>solid_dna</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dog"></malb-icon>
		<p>solid_dog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dollar_sign"></malb-icon>
		<p>solid_dollar_sign</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dolly_flatbed"></malb-icon>
		<p>solid_dolly_flatbed</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dolly"></malb-icon>
		<p>solid_dolly</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_donate"></malb-icon>
		<p>solid_donate</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_door_closed"></malb-icon>
		<p>solid_door_closed</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_door_open"></malb-icon>
		<p>solid_door_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dot_circle"></malb-icon>
		<p>solid_dot_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dove"></malb-icon>
		<p>solid_dove</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_download"></malb-icon>
		<p>solid_download</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_drafting_compass"></malb-icon>
		<p>solid_drafting_compass</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dragon"></malb-icon>
		<p>solid_dragon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_draw_polygon"></malb-icon>
		<p>solid_draw_polygon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_drum_steelpan"></malb-icon>
		<p>solid_drum_steelpan</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_drum"></malb-icon>
		<p>solid_drum</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_drumstick_bite"></malb-icon>
		<p>solid_drumstick_bite</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dumbbell"></malb-icon>
		<p>solid_dumbbell</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dumpster_fire"></malb-icon>
		<p>solid_dumpster_fire</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dumpster"></malb-icon>
		<p>solid_dumpster</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_dungeon"></malb-icon>
		<p>solid_dungeon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_edit"></malb-icon>
		<p>solid_edit</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_egg"></malb-icon>
		<p>solid_egg</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_eject"></malb-icon>
		<p>solid_eject</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ellipsis_h"></malb-icon>
		<p>solid_ellipsis_h</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ellipsis_v"></malb-icon>
		<p>solid_ellipsis_v</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_envelope_open_text"></malb-icon>
		<p>solid_envelope_open_text</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_envelope_open"></malb-icon>
		<p>solid_envelope_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_envelope_square"></malb-icon>
		<p>solid_envelope_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_envelope"></malb-icon>
		<p>solid_envelope</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_equals"></malb-icon>
		<p>solid_equals</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_eraser"></malb-icon>
		<p>solid_eraser</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ethernet"></malb-icon>
		<p>solid_ethernet</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_euro_sign"></malb-icon>
		<p>solid_euro_sign</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_exchange_alt"></malb-icon>
		<p>solid_exchange_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_exclamation_circle"></malb-icon>
		<p>solid_exclamation_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_exclamation_triangle"></malb-icon>
		<p>solid_exclamation_triangle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_exclamation"></malb-icon>
		<p>solid_exclamation</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_expand_alt"></malb-icon>
		<p>solid_expand_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_expand_arrows_alt"></malb-icon>
		<p>solid_expand_arrows_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_expand"></malb-icon>
		<p>solid_expand</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_external_link_alt"></malb-icon>
		<p>solid_external_link_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_external_link_square_alt"></malb-icon>
		<p>solid_external_link_square_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_eye_dropper"></malb-icon>
		<p>solid_eye_dropper</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_eye_slash"></malb-icon>
		<p>solid_eye_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_eye"></malb-icon>
		<p>solid_eye</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fan"></malb-icon>
		<p>solid_fan</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fast_backward"></malb-icon>
		<p>solid_fast_backward</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fast_forward"></malb-icon>
		<p>solid_fast_forward</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_faucet"></malb-icon>
		<p>solid_faucet</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fax"></malb-icon>
		<p>solid_fax</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_feather_alt"></malb-icon>
		<p>solid_feather_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_feather"></malb-icon>
		<p>solid_feather</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_female"></malb-icon>
		<p>solid_female</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fighter_jet"></malb-icon>
		<p>solid_fighter_jet</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_alt"></malb-icon>
		<p>solid_file_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_archive"></malb-icon>
		<p>solid_file_archive</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_audio"></malb-icon>
		<p>solid_file_audio</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_code"></malb-icon>
		<p>solid_file_code</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_contract"></malb-icon>
		<p>solid_file_contract</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_csv"></malb-icon>
		<p>solid_file_csv</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_download"></malb-icon>
		<p>solid_file_download</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_excel"></malb-icon>
		<p>solid_file_excel</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_export"></malb-icon>
		<p>solid_file_export</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_image"></malb-icon>
		<p>solid_file_image</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_import"></malb-icon>
		<p>solid_file_import</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_invoice_dollar"></malb-icon>
		<p>solid_file_invoice_dollar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_invoice"></malb-icon>
		<p>solid_file_invoice</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_medical_alt"></malb-icon>
		<p>solid_file_medical_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_medical"></malb-icon>
		<p>solid_file_medical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_pdf"></malb-icon>
		<p>solid_file_pdf</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_powerpoint"></malb-icon>
		<p>solid_file_powerpoint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_prescription"></malb-icon>
		<p>solid_file_prescription</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_signature"></malb-icon>
		<p>solid_file_signature</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_upload"></malb-icon>
		<p>solid_file_upload</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_video"></malb-icon>
		<p>solid_file_video</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file_word"></malb-icon>
		<p>solid_file_word</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_file"></malb-icon>
		<p>solid_file</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fill_drip"></malb-icon>
		<p>solid_fill_drip</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fill"></malb-icon>
		<p>solid_fill</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_film"></malb-icon>
		<p>solid_film</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_filter"></malb-icon>
		<p>solid_filter</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fingerprint"></malb-icon>
		<p>solid_fingerprint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fire_alt"></malb-icon>
		<p>solid_fire_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fire_extinguisher"></malb-icon>
		<p>solid_fire_extinguisher</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fire"></malb-icon>
		<p>solid_fire</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_first_aid"></malb-icon>
		<p>solid_first_aid</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fish"></malb-icon>
		<p>solid_fish</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_fist_raised"></malb-icon>
		<p>solid_fist_raised</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_flag_checkered"></malb-icon>
		<p>solid_flag_checkered</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_flag_usa"></malb-icon>
		<p>solid_flag_usa</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_flag"></malb-icon>
		<p>solid_flag</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_flask"></malb-icon>
		<p>solid_flask</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_flushed"></malb-icon>
		<p>solid_flushed</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_folder_minus"></malb-icon>
		<p>solid_folder_minus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_folder_open"></malb-icon>
		<p>solid_folder_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_folder_plus"></malb-icon>
		<p>solid_folder_plus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_folder"></malb-icon>
		<p>solid_folder</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_font_awesome_logo_full"></malb-icon>
		<p>solid_font_awesome_logo_full</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_font"></malb-icon>
		<p>solid_font</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_football_ball"></malb-icon>
		<p>solid_football_ball</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_forward"></malb-icon>
		<p>solid_forward</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_frog"></malb-icon>
		<p>solid_frog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_frown_open"></malb-icon>
		<p>solid_frown_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_frown"></malb-icon>
		<p>solid_frown</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_funnel_dollar"></malb-icon>
		<p>solid_funnel_dollar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_futbol"></malb-icon>
		<p>solid_futbol</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_gamepad"></malb-icon>
		<p>solid_gamepad</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_gas_pump"></malb-icon>
		<p>solid_gas_pump</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_gavel"></malb-icon>
		<p>solid_gavel</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_gem"></malb-icon>
		<p>solid_gem</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_genderless"></malb-icon>
		<p>solid_genderless</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ghost"></malb-icon>
		<p>solid_ghost</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_gift"></malb-icon>
		<p>solid_gift</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_gifts"></malb-icon>
		<p>solid_gifts</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_glass_cheers"></malb-icon>
		<p>solid_glass_cheers</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_glass_martini_alt"></malb-icon>
		<p>solid_glass_martini_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_glass_martini"></malb-icon>
		<p>solid_glass_martini</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_glass_whiskey"></malb-icon>
		<p>solid_glass_whiskey</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_glasses"></malb-icon>
		<p>solid_glasses</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_globe_africa"></malb-icon>
		<p>solid_globe_africa</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_globe_americas"></malb-icon>
		<p>solid_globe_americas</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_globe_asia"></malb-icon>
		<p>solid_globe_asia</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_globe_europe"></malb-icon>
		<p>solid_globe_europe</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_globe"></malb-icon>
		<p>solid_globe</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_golf_ball"></malb-icon>
		<p>solid_golf_ball</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_gopuram"></malb-icon>
		<p>solid_gopuram</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_graduation_cap"></malb-icon>
		<p>solid_graduation_cap</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_greater_than_equal"></malb-icon>
		<p>solid_greater_than_equal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_greater_than"></malb-icon>
		<p>solid_greater_than</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grimace"></malb-icon>
		<p>solid_grimace</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_alt"></malb-icon>
		<p>solid_grin_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_beam_sweat"></malb-icon>
		<p>solid_grin_beam_sweat</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_beam"></malb-icon>
		<p>solid_grin_beam</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_hearts"></malb-icon>
		<p>solid_grin_hearts</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_squint_tears"></malb-icon>
		<p>solid_grin_squint_tears</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_squint"></malb-icon>
		<p>solid_grin_squint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_stars"></malb-icon>
		<p>solid_grin_stars</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_tears"></malb-icon>
		<p>solid_grin_tears</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_tongue_squint"></malb-icon>
		<p>solid_grin_tongue_squint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_tongue_wink"></malb-icon>
		<p>solid_grin_tongue_wink</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_tongue"></malb-icon>
		<p>solid_grin_tongue</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin_wink"></malb-icon>
		<p>solid_grin_wink</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grin"></malb-icon>
		<p>solid_grin</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grip_horizontal"></malb-icon>
		<p>solid_grip_horizontal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grip_lines_vertical"></malb-icon>
		<p>solid_grip_lines_vertical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grip_lines"></malb-icon>
		<p>solid_grip_lines</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_grip_vertical"></malb-icon>
		<p>solid_grip_vertical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_guitar"></malb-icon>
		<p>solid_guitar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_h_square"></malb-icon>
		<p>solid_h_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hamburger"></malb-icon>
		<p>solid_hamburger</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hammer"></malb-icon>
		<p>solid_hammer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hamsa"></malb-icon>
		<p>solid_hamsa</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_holding_heart"></malb-icon>
		<p>solid_hand_holding_heart</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_holding_medical"></malb-icon>
		<p>solid_hand_holding_medical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_holding_usd"></malb-icon>
		<p>solid_hand_holding_usd</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_holding_water"></malb-icon>
		<p>solid_hand_holding_water</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_holding"></malb-icon>
		<p>solid_hand_holding</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_lizard"></malb-icon>
		<p>solid_hand_lizard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_middle_finger"></malb-icon>
		<p>solid_hand_middle_finger</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_paper"></malb-icon>
		<p>solid_hand_paper</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_peace"></malb-icon>
		<p>solid_hand_peace</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_point_down"></malb-icon>
		<p>solid_hand_point_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_point_left"></malb-icon>
		<p>solid_hand_point_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_point_right"></malb-icon>
		<p>solid_hand_point_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_point_up"></malb-icon>
		<p>solid_hand_point_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_pointer"></malb-icon>
		<p>solid_hand_pointer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_rock"></malb-icon>
		<p>solid_hand_rock</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_scissors"></malb-icon>
		<p>solid_hand_scissors</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_sparkles"></malb-icon>
		<p>solid_hand_sparkles</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hand_spock"></malb-icon>
		<p>solid_hand_spock</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hands_helping"></malb-icon>
		<p>solid_hands_helping</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hands_wash"></malb-icon>
		<p>solid_hands_wash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hands"></malb-icon>
		<p>solid_hands</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_handshake_alt_slash"></malb-icon>
		<p>solid_handshake_alt_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_handshake_slash"></malb-icon>
		<p>solid_handshake_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_handshake"></malb-icon>
		<p>solid_handshake</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hanukiah"></malb-icon>
		<p>solid_hanukiah</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hard_hat"></malb-icon>
		<p>solid_hard_hat</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hashtag"></malb-icon>
		<p>solid_hashtag</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hat_cowboy_side"></malb-icon>
		<p>solid_hat_cowboy_side</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hat_cowboy"></malb-icon>
		<p>solid_hat_cowboy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hat_wizard"></malb-icon>
		<p>solid_hat_wizard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hdd"></malb-icon>
		<p>solid_hdd</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_head_side_cough_slash"></malb-icon>
		<p>solid_head_side_cough_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_head_side_cough"></malb-icon>
		<p>solid_head_side_cough</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_head_side_mask"></malb-icon>
		<p>solid_head_side_mask</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_head_side_virus"></malb-icon>
		<p>solid_head_side_virus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_heading"></malb-icon>
		<p>solid_heading</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_headphones_alt"></malb-icon>
		<p>solid_headphones_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_headphones"></malb-icon>
		<p>solid_headphones</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_headset"></malb-icon>
		<p>solid_headset</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_heart_broken"></malb-icon>
		<p>solid_heart_broken</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_heart"></malb-icon>
		<p>solid_heart</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_heartbeat"></malb-icon>
		<p>solid_heartbeat</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_helicopter"></malb-icon>
		<p>solid_helicopter</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_highlighter"></malb-icon>
		<p>solid_highlighter</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hiking"></malb-icon>
		<p>solid_hiking</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hippo"></malb-icon>
		<p>solid_hippo</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_history"></malb-icon>
		<p>solid_history</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hockey_puck"></malb-icon>
		<p>solid_hockey_puck</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_holly_berry"></malb-icon>
		<p>solid_holly_berry</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_home"></malb-icon>
		<p>solid_home</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_horse_head"></malb-icon>
		<p>solid_horse_head</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_horse"></malb-icon>
		<p>solid_horse</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hospital_alt"></malb-icon>
		<p>solid_hospital_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hospital_symbol"></malb-icon>
		<p>solid_hospital_symbol</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hospital_user"></malb-icon>
		<p>solid_hospital_user</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hospital"></malb-icon>
		<p>solid_hospital</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hot_tub"></malb-icon>
		<p>solid_hot_tub</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hotdog"></malb-icon>
		<p>solid_hotdog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hotel"></malb-icon>
		<p>solid_hotel</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hourglass_end"></malb-icon>
		<p>solid_hourglass_end</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hourglass_half"></malb-icon>
		<p>solid_hourglass_half</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hourglass_start"></malb-icon>
		<p>solid_hourglass_start</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hourglass"></malb-icon>
		<p>solid_hourglass</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_house_damage"></malb-icon>
		<p>solid_house_damage</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_house_user"></malb-icon>
		<p>solid_house_user</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_hryvnia"></malb-icon>
		<p>solid_hryvnia</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_i_cursor"></malb-icon>
		<p>solid_i_cursor</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ice_cream"></malb-icon>
		<p>solid_ice_cream</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_icicles"></malb-icon>
		<p>solid_icicles</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_icons"></malb-icon>
		<p>solid_icons</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_id_badge"></malb-icon>
		<p>solid_id_badge</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_id_card_alt"></malb-icon>
		<p>solid_id_card_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_id_card"></malb-icon>
		<p>solid_id_card</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_igloo"></malb-icon>
		<p>solid_igloo</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_image"></malb-icon>
		<p>solid_image</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_images"></malb-icon>
		<p>solid_images</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_inbox"></malb-icon>
		<p>solid_inbox</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_indent"></malb-icon>
		<p>solid_indent</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_industry"></malb-icon>
		<p>solid_industry</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_infinity"></malb-icon>
		<p>solid_infinity</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_info_circle"></malb-icon>
		<p>solid_info_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_info"></malb-icon>
		<p>solid_info</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_italic"></malb-icon>
		<p>solid_italic</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_jedi"></malb-icon>
		<p>solid_jedi</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_joint"></malb-icon>
		<p>solid_joint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_journal_whills"></malb-icon>
		<p>solid_journal_whills</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_kaaba"></malb-icon>
		<p>solid_kaaba</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_key"></malb-icon>
		<p>solid_key</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_keyboard"></malb-icon>
		<p>solid_keyboard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_khanda"></malb-icon>
		<p>solid_khanda</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_kiss_beam"></malb-icon>
		<p>solid_kiss_beam</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_kiss_wink_heart"></malb-icon>
		<p>solid_kiss_wink_heart</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_kiss"></malb-icon>
		<p>solid_kiss</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_kiwi_bird"></malb-icon>
		<p>solid_kiwi_bird</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_landmark"></malb-icon>
		<p>solid_landmark</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_language"></malb-icon>
		<p>solid_language</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_laptop_code"></malb-icon>
		<p>solid_laptop_code</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_laptop_house"></malb-icon>
		<p>solid_laptop_house</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_laptop_medical"></malb-icon>
		<p>solid_laptop_medical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_laptop"></malb-icon>
		<p>solid_laptop</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_laugh_beam"></malb-icon>
		<p>solid_laugh_beam</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_laugh_squint"></malb-icon>
		<p>solid_laugh_squint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_laugh_wink"></malb-icon>
		<p>solid_laugh_wink</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_laugh"></malb-icon>
		<p>solid_laugh</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_layer_group"></malb-icon>
		<p>solid_layer_group</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_leaf"></malb-icon>
		<p>solid_leaf</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_lemon"></malb-icon>
		<p>solid_lemon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_less_than_equal"></malb-icon>
		<p>solid_less_than_equal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_less_than"></malb-icon>
		<p>solid_less_than</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_level_down_alt"></malb-icon>
		<p>solid_level_down_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_level_up_alt"></malb-icon>
		<p>solid_level_up_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_life_ring"></malb-icon>
		<p>solid_life_ring</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_lightbulb"></malb-icon>
		<p>solid_lightbulb</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_link"></malb-icon>
		<p>solid_link</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_lira_sign"></malb-icon>
		<p>solid_lira_sign</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_list_alt"></malb-icon>
		<p>solid_list_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_list_ol"></malb-icon>
		<p>solid_list_ol</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_list_ul"></malb-icon>
		<p>solid_list_ul</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_list"></malb-icon>
		<p>solid_list</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_location_arrow"></malb-icon>
		<p>solid_location_arrow</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_lock_open"></malb-icon>
		<p>solid_lock_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_lock"></malb-icon>
		<p>solid_lock</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_long_arrow_alt_down"></malb-icon>
		<p>solid_long_arrow_alt_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_long_arrow_alt_left"></malb-icon>
		<p>solid_long_arrow_alt_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_long_arrow_alt_right"></malb-icon>
		<p>solid_long_arrow_alt_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_long_arrow_alt_up"></malb-icon>
		<p>solid_long_arrow_alt_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_low_vision"></malb-icon>
		<p>solid_low_vision</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_luggage_cart"></malb-icon>
		<p>solid_luggage_cart</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_lungs_virus"></malb-icon>
		<p>solid_lungs_virus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_lungs"></malb-icon>
		<p>solid_lungs</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_magic"></malb-icon>
		<p>solid_magic</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_magnet"></malb-icon>
		<p>solid_magnet</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mail_bulk"></malb-icon>
		<p>solid_mail_bulk</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_male"></malb-icon>
		<p>solid_male</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_map_marked_alt"></malb-icon>
		<p>solid_map_marked_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_map_marked"></malb-icon>
		<p>solid_map_marked</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_map_marker_alt"></malb-icon>
		<p>solid_map_marker_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_map_marker"></malb-icon>
		<p>solid_map_marker</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_map_pin"></malb-icon>
		<p>solid_map_pin</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_map_signs"></malb-icon>
		<p>solid_map_signs</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_map"></malb-icon>
		<p>solid_map</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_marker"></malb-icon>
		<p>solid_marker</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mars_double"></malb-icon>
		<p>solid_mars_double</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mars_stroke_h"></malb-icon>
		<p>solid_mars_stroke_h</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mars_stroke_v"></malb-icon>
		<p>solid_mars_stroke_v</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mars_stroke"></malb-icon>
		<p>solid_mars_stroke</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mars"></malb-icon>
		<p>solid_mars</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mask"></malb-icon>
		<p>solid_mask</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_medal"></malb-icon>
		<p>solid_medal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_medkit"></malb-icon>
		<p>solid_medkit</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_meh_blank"></malb-icon>
		<p>solid_meh_blank</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_meh_rolling_eyes"></malb-icon>
		<p>solid_meh_rolling_eyes</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_meh"></malb-icon>
		<p>solid_meh</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_memory"></malb-icon>
		<p>solid_memory</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_menorah"></malb-icon>
		<p>solid_menorah</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mercury"></malb-icon>
		<p>solid_mercury</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_meteor"></malb-icon>
		<p>solid_meteor</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_microchip"></malb-icon>
		<p>solid_microchip</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_microphone_alt_slash"></malb-icon>
		<p>solid_microphone_alt_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_microphone_alt"></malb-icon>
		<p>solid_microphone_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_microphone_slash"></malb-icon>
		<p>solid_microphone_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_microphone"></malb-icon>
		<p>solid_microphone</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_microscope"></malb-icon>
		<p>solid_microscope</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_minus_circle"></malb-icon>
		<p>solid_minus_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_minus_square"></malb-icon>
		<p>solid_minus_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_minus"></malb-icon>
		<p>solid_minus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mitten"></malb-icon>
		<p>solid_mitten</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mobile_alt"></malb-icon>
		<p>solid_mobile_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mobile"></malb-icon>
		<p>solid_mobile</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_money_bill_alt"></malb-icon>
		<p>solid_money_bill_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_money_bill_wave_alt"></malb-icon>
		<p>solid_money_bill_wave_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_money_bill_wave"></malb-icon>
		<p>solid_money_bill_wave</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_money_bill"></malb-icon>
		<p>solid_money_bill</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_money_check_alt"></malb-icon>
		<p>solid_money_check_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_money_check"></malb-icon>
		<p>solid_money_check</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_monument"></malb-icon>
		<p>solid_monument</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_moon"></malb-icon>
		<p>solid_moon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mortar_pestle"></malb-icon>
		<p>solid_mortar_pestle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mosque"></malb-icon>
		<p>solid_mosque</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_motorcycle"></malb-icon>
		<p>solid_motorcycle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mountain"></malb-icon>
		<p>solid_mountain</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mouse_pointer"></malb-icon>
		<p>solid_mouse_pointer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mouse"></malb-icon>
		<p>solid_mouse</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_mug_hot"></malb-icon>
		<p>solid_mug_hot</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_music"></malb-icon>
		<p>solid_music</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_network_wired"></malb-icon>
		<p>solid_network_wired</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_neuter"></malb-icon>
		<p>solid_neuter</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_newspaper"></malb-icon>
		<p>solid_newspaper</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_not_equal"></malb-icon>
		<p>solid_not_equal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_notes_medical"></malb-icon>
		<p>solid_notes_medical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_object_group"></malb-icon>
		<p>solid_object_group</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_object_ungroup"></malb-icon>
		<p>solid_object_ungroup</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_oil_can"></malb-icon>
		<p>solid_oil_can</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_om"></malb-icon>
		<p>solid_om</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_otter"></malb-icon>
		<p>solid_otter</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_outdent"></malb-icon>
		<p>solid_outdent</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pager"></malb-icon>
		<p>solid_pager</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_paint_brush"></malb-icon>
		<p>solid_paint_brush</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_paint_roller"></malb-icon>
		<p>solid_paint_roller</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_palette"></malb-icon>
		<p>solid_palette</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pallet"></malb-icon>
		<p>solid_pallet</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_paper_plane"></malb-icon>
		<p>solid_paper_plane</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_paperclip"></malb-icon>
		<p>solid_paperclip</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_parachute_box"></malb-icon>
		<p>solid_parachute_box</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_paragraph"></malb-icon>
		<p>solid_paragraph</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_parking"></malb-icon>
		<p>solid_parking</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_passport"></malb-icon>
		<p>solid_passport</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pastafarianism"></malb-icon>
		<p>solid_pastafarianism</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_paste"></malb-icon>
		<p>solid_paste</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pause_circle"></malb-icon>
		<p>solid_pause_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pause"></malb-icon>
		<p>solid_pause</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_paw"></malb-icon>
		<p>solid_paw</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_peace"></malb-icon>
		<p>solid_peace</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pen_alt"></malb-icon>
		<p>solid_pen_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pen_fancy"></malb-icon>
		<p>solid_pen_fancy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pen_nib"></malb-icon>
		<p>solid_pen_nib</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pen_square"></malb-icon>
		<p>solid_pen_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pen"></malb-icon>
		<p>solid_pen</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pencil_alt"></malb-icon>
		<p>solid_pencil_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pencil_ruler"></malb-icon>
		<p>solid_pencil_ruler</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_people_arrows"></malb-icon>
		<p>solid_people_arrows</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_people_carry"></malb-icon>
		<p>solid_people_carry</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pepper_hot"></malb-icon>
		<p>solid_pepper_hot</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_percent"></malb-icon>
		<p>solid_percent</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_percentage"></malb-icon>
		<p>solid_percentage</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_person_booth"></malb-icon>
		<p>solid_person_booth</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_phone_alt"></malb-icon>
		<p>solid_phone_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_phone_slash"></malb-icon>
		<p>solid_phone_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_phone_square_alt"></malb-icon>
		<p>solid_phone_square_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_phone_square"></malb-icon>
		<p>solid_phone_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_phone_volume"></malb-icon>
		<p>solid_phone_volume</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_phone"></malb-icon>
		<p>solid_phone</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_photo_video"></malb-icon>
		<p>solid_photo_video</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_piggy_bank"></malb-icon>
		<p>solid_piggy_bank</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pills"></malb-icon>
		<p>solid_pills</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pizza_slice"></malb-icon>
		<p>solid_pizza_slice</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_place_of_worship"></malb-icon>
		<p>solid_place_of_worship</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_plane_arrival"></malb-icon>
		<p>solid_plane_arrival</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_plane_departure"></malb-icon>
		<p>solid_plane_departure</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_plane_slash"></malb-icon>
		<p>solid_plane_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_plane"></malb-icon>
		<p>solid_plane</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_play_circle"></malb-icon>
		<p>solid_play_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_play"></malb-icon>
		<p>solid_play</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_plug"></malb-icon>
		<p>solid_plug</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_plus_circle"></malb-icon>
		<p>solid_plus_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_plus_square"></malb-icon>
		<p>solid_plus_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_plus"></malb-icon>
		<p>solid_plus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_podcast"></malb-icon>
		<p>solid_podcast</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_poll_h"></malb-icon>
		<p>solid_poll_h</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_poll"></malb-icon>
		<p>solid_poll</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_poo_storm"></malb-icon>
		<p>solid_poo_storm</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_poo"></malb-icon>
		<p>solid_poo</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_poop"></malb-icon>
		<p>solid_poop</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_portrait"></malb-icon>
		<p>solid_portrait</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pound_sign"></malb-icon>
		<p>solid_pound_sign</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_power_off"></malb-icon>
		<p>solid_power_off</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pray"></malb-icon>
		<p>solid_pray</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_praying_hands"></malb-icon>
		<p>solid_praying_hands</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_prescription_bottle_alt"></malb-icon>
		<p>solid_prescription_bottle_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_prescription_bottle"></malb-icon>
		<p>solid_prescription_bottle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_prescription"></malb-icon>
		<p>solid_prescription</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_print"></malb-icon>
		<p>solid_print</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_procedures"></malb-icon>
		<p>solid_procedures</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_project_diagram"></malb-icon>
		<p>solid_project_diagram</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pump_medical"></malb-icon>
		<p>solid_pump_medical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_pump_soap"></malb-icon>
		<p>solid_pump_soap</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_puzzle_piece"></malb-icon>
		<p>solid_puzzle_piece</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_qrcode"></malb-icon>
		<p>solid_qrcode</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_question_circle"></malb-icon>
		<p>solid_question_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_question"></malb-icon>
		<p>solid_question</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_quidditch"></malb-icon>
		<p>solid_quidditch</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_quote_left"></malb-icon>
		<p>solid_quote_left</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_quote_right"></malb-icon>
		<p>solid_quote_right</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_quran"></malb-icon>
		<p>solid_quran</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_radiation_alt"></malb-icon>
		<p>solid_radiation_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_radiation"></malb-icon>
		<p>solid_radiation</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_rainbow"></malb-icon>
		<p>solid_rainbow</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_random"></malb-icon>
		<p>solid_random</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_receipt"></malb-icon>
		<p>solid_receipt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_record_vinyl"></malb-icon>
		<p>solid_record_vinyl</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_recycle"></malb-icon>
		<p>solid_recycle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_redo_alt"></malb-icon>
		<p>solid_redo_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_redo"></malb-icon>
		<p>solid_redo</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_registered"></malb-icon>
		<p>solid_registered</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_remove_format"></malb-icon>
		<p>solid_remove_format</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_reply_all"></malb-icon>
		<p>solid_reply_all</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_reply"></malb-icon>
		<p>solid_reply</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_republican"></malb-icon>
		<p>solid_republican</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_restroom"></malb-icon>
		<p>solid_restroom</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_retweet"></malb-icon>
		<p>solid_retweet</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ribbon"></malb-icon>
		<p>solid_ribbon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ring"></malb-icon>
		<p>solid_ring</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_road"></malb-icon>
		<p>solid_road</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_robot"></malb-icon>
		<p>solid_robot</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_rocket"></malb-icon>
		<p>solid_rocket</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_route"></malb-icon>
		<p>solid_route</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_rss_square"></malb-icon>
		<p>solid_rss_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_rss"></malb-icon>
		<p>solid_rss</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ruble_sign"></malb-icon>
		<p>solid_ruble_sign</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ruler_combined"></malb-icon>
		<p>solid_ruler_combined</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ruler_horizontal"></malb-icon>
		<p>solid_ruler_horizontal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ruler_vertical"></malb-icon>
		<p>solid_ruler_vertical</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ruler"></malb-icon>
		<p>solid_ruler</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_running"></malb-icon>
		<p>solid_running</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_rupee_sign"></malb-icon>
		<p>solid_rupee_sign</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sad_cry"></malb-icon>
		<p>solid_sad_cry</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sad_tear"></malb-icon>
		<p>solid_sad_tear</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_satellite_dish"></malb-icon>
		<p>solid_satellite_dish</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_satellite"></malb-icon>
		<p>solid_satellite</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_save"></malb-icon>
		<p>solid_save</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_school"></malb-icon>
		<p>solid_school</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_screwdriver"></malb-icon>
		<p>solid_screwdriver</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_scroll"></malb-icon>
		<p>solid_scroll</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sd_card"></malb-icon>
		<p>solid_sd_card</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_search_dollar"></malb-icon>
		<p>solid_search_dollar</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_search_location"></malb-icon>
		<p>solid_search_location</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_search_minus"></malb-icon>
		<p>solid_search_minus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_search_plus"></malb-icon>
		<p>solid_search_plus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_search"></malb-icon>
		<p>solid_search</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_seedling"></malb-icon>
		<p>solid_seedling</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_server"></malb-icon>
		<p>solid_server</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shapes"></malb-icon>
		<p>solid_shapes</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_share_alt_square"></malb-icon>
		<p>solid_share_alt_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_share_alt"></malb-icon>
		<p>solid_share_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_share_square"></malb-icon>
		<p>solid_share_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_share"></malb-icon>
		<p>solid_share</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shekel_sign"></malb-icon>
		<p>solid_shekel_sign</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shield_alt"></malb-icon>
		<p>solid_shield_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shield_virus"></malb-icon>
		<p>solid_shield_virus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ship"></malb-icon>
		<p>solid_ship</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shipping_fast"></malb-icon>
		<p>solid_shipping_fast</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shoe_prints"></malb-icon>
		<p>solid_shoe_prints</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shopping_bag"></malb-icon>
		<p>solid_shopping_bag</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shopping_basket"></malb-icon>
		<p>solid_shopping_basket</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shopping_cart"></malb-icon>
		<p>solid_shopping_cart</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shower"></malb-icon>
		<p>solid_shower</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_shuttle_van"></malb-icon>
		<p>solid_shuttle_van</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sign_in_alt"></malb-icon>
		<p>solid_sign_in_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sign_language"></malb-icon>
		<p>solid_sign_language</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sign_out_alt"></malb-icon>
		<p>solid_sign_out_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sign"></malb-icon>
		<p>solid_sign</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_signal"></malb-icon>
		<p>solid_signal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_signature"></malb-icon>
		<p>solid_signature</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sim_card"></malb-icon>
		<p>solid_sim_card</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sink"></malb-icon>
		<p>solid_sink</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sitemap"></malb-icon>
		<p>solid_sitemap</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_skating"></malb-icon>
		<p>solid_skating</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_skiing_nordic"></malb-icon>
		<p>solid_skiing_nordic</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_skiing"></malb-icon>
		<p>solid_skiing</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_skull_crossbones"></malb-icon>
		<p>solid_skull_crossbones</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_skull"></malb-icon>
		<p>solid_skull</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_slash"></malb-icon>
		<p>solid_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sleigh"></malb-icon>
		<p>solid_sleigh</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sliders_h"></malb-icon>
		<p>solid_sliders_h</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_smile_beam"></malb-icon>
		<p>solid_smile_beam</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_smile_wink"></malb-icon>
		<p>solid_smile_wink</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_smile"></malb-icon>
		<p>solid_smile</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_smog"></malb-icon>
		<p>solid_smog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_smoking_ban"></malb-icon>
		<p>solid_smoking_ban</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_smoking"></malb-icon>
		<p>solid_smoking</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sms"></malb-icon>
		<p>solid_sms</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_snowboarding"></malb-icon>
		<p>solid_snowboarding</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_snowflake"></malb-icon>
		<p>solid_snowflake</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_snowman"></malb-icon>
		<p>solid_snowman</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_snowplow"></malb-icon>
		<p>solid_snowplow</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_soap"></malb-icon>
		<p>solid_soap</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_socks"></malb-icon>
		<p>solid_socks</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_solar_panel"></malb-icon>
		<p>solid_solar_panel</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_alpha_down_alt"></malb-icon>
		<p>solid_sort_alpha_down_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_alpha_down"></malb-icon>
		<p>solid_sort_alpha_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_alpha_up_alt"></malb-icon>
		<p>solid_sort_alpha_up_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_alpha_up"></malb-icon>
		<p>solid_sort_alpha_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_amount_down_alt"></malb-icon>
		<p>solid_sort_amount_down_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_amount_down"></malb-icon>
		<p>solid_sort_amount_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_amount_up_alt"></malb-icon>
		<p>solid_sort_amount_up_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_amount_up"></malb-icon>
		<p>solid_sort_amount_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_down"></malb-icon>
		<p>solid_sort_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_numeric_down_alt"></malb-icon>
		<p>solid_sort_numeric_down_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_numeric_down"></malb-icon>
		<p>solid_sort_numeric_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_numeric_up_alt"></malb-icon>
		<p>solid_sort_numeric_up_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_numeric_up"></malb-icon>
		<p>solid_sort_numeric_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort_up"></malb-icon>
		<p>solid_sort_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sort"></malb-icon>
		<p>solid_sort</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_spa"></malb-icon>
		<p>solid_spa</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_space_shuttle"></malb-icon>
		<p>solid_space_shuttle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_spell_check"></malb-icon>
		<p>solid_spell_check</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_spider"></malb-icon>
		<p>solid_spider</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_spinner"></malb-icon>
		<p>solid_spinner</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_splotch"></malb-icon>
		<p>solid_splotch</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_spray_can"></malb-icon>
		<p>solid_spray_can</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_square_full"></malb-icon>
		<p>solid_square_full</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_square_root_alt"></malb-icon>
		<p>solid_square_root_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_square"></malb-icon>
		<p>solid_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_stamp"></malb-icon>
		<p>solid_stamp</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_star_and_crescent"></malb-icon>
		<p>solid_star_and_crescent</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_star_half_alt"></malb-icon>
		<p>solid_star_half_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_star_half"></malb-icon>
		<p>solid_star_half</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_star_of_david"></malb-icon>
		<p>solid_star_of_david</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_star_of_life"></malb-icon>
		<p>solid_star_of_life</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_star"></malb-icon>
		<p>solid_star</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_step_backward"></malb-icon>
		<p>solid_step_backward</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_step_forward"></malb-icon>
		<p>solid_step_forward</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_stethoscope"></malb-icon>
		<p>solid_stethoscope</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sticky_note"></malb-icon>
		<p>solid_sticky_note</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_stop_circle"></malb-icon>
		<p>solid_stop_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_stop"></malb-icon>
		<p>solid_stop</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_stopwatch_20"></malb-icon>
		<p>solid_stopwatch_20</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_stopwatch"></malb-icon>
		<p>solid_stopwatch</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_store_alt_slash"></malb-icon>
		<p>solid_store_alt_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_store_alt"></malb-icon>
		<p>solid_store_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_store_slash"></malb-icon>
		<p>solid_store_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_store"></malb-icon>
		<p>solid_store</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_stream"></malb-icon>
		<p>solid_stream</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_street_view"></malb-icon>
		<p>solid_street_view</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_strikethrough"></malb-icon>
		<p>solid_strikethrough</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_stroopwafel"></malb-icon>
		<p>solid_stroopwafel</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_subscript"></malb-icon>
		<p>solid_subscript</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_subway"></malb-icon>
		<p>solid_subway</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_suitcase_rolling"></malb-icon>
		<p>solid_suitcase_rolling</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_suitcase"></malb-icon>
		<p>solid_suitcase</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sun"></malb-icon>
		<p>solid_sun</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_superscript"></malb-icon>
		<p>solid_superscript</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_surprise"></malb-icon>
		<p>solid_surprise</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_swatchbook"></malb-icon>
		<p>solid_swatchbook</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_swimmer"></malb-icon>
		<p>solid_swimmer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_swimming_pool"></malb-icon>
		<p>solid_swimming_pool</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_synagogue"></malb-icon>
		<p>solid_synagogue</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sync_alt"></malb-icon>
		<p>solid_sync_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_sync"></malb-icon>
		<p>solid_sync</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_syringe"></malb-icon>
		<p>solid_syringe</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_table_tennis"></malb-icon>
		<p>solid_table_tennis</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_table"></malb-icon>
		<p>solid_table</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tablet_alt"></malb-icon>
		<p>solid_tablet_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tablet"></malb-icon>
		<p>solid_tablet</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tablets"></malb-icon>
		<p>solid_tablets</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tachometer_alt"></malb-icon>
		<p>solid_tachometer_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tag"></malb-icon>
		<p>solid_tag</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tags"></malb-icon>
		<p>solid_tags</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tape"></malb-icon>
		<p>solid_tape</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tasks"></malb-icon>
		<p>solid_tasks</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_taxi"></malb-icon>
		<p>solid_taxi</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_teeth_open"></malb-icon>
		<p>solid_teeth_open</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_teeth"></malb-icon>
		<p>solid_teeth</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_temperature_high"></malb-icon>
		<p>solid_temperature_high</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_temperature_low"></malb-icon>
		<p>solid_temperature_low</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tenge"></malb-icon>
		<p>solid_tenge</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_terminal"></malb-icon>
		<p>solid_terminal</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_text_height"></malb-icon>
		<p>solid_text_height</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_text_width"></malb-icon>
		<p>solid_text_width</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_th_large"></malb-icon>
		<p>solid_th_large</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_th_list"></malb-icon>
		<p>solid_th_list</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_th"></malb-icon>
		<p>solid_th</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_theater_masks"></malb-icon>
		<p>solid_theater_masks</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_thermometer_empty"></malb-icon>
		<p>solid_thermometer_empty</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_thermometer_full"></malb-icon>
		<p>solid_thermometer_full</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_thermometer_half"></malb-icon>
		<p>solid_thermometer_half</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_thermometer_quarter"></malb-icon>
		<p>solid_thermometer_quarter</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_thermometer_three_quarters"></malb-icon>
		<p>solid_thermometer_three_quarters</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_thermometer"></malb-icon>
		<p>solid_thermometer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_thumbs_down"></malb-icon>
		<p>solid_thumbs_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_thumbs_up"></malb-icon>
		<p>solid_thumbs_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_thumbtack"></malb-icon>
		<p>solid_thumbtack</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_ticket_alt"></malb-icon>
		<p>solid_ticket_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_times_circle"></malb-icon>
		<p>solid_times_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_times"></malb-icon>
		<p>solid_times</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tint_slash"></malb-icon>
		<p>solid_tint_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tint"></malb-icon>
		<p>solid_tint</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tired"></malb-icon>
		<p>solid_tired</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_toggle_off"></malb-icon>
		<p>solid_toggle_off</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_toggle_on"></malb-icon>
		<p>solid_toggle_on</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_toilet_paper_slash"></malb-icon>
		<p>solid_toilet_paper_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_toilet_paper"></malb-icon>
		<p>solid_toilet_paper</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_toilet"></malb-icon>
		<p>solid_toilet</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_toolbox"></malb-icon>
		<p>solid_toolbox</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tools"></malb-icon>
		<p>solid_tools</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tooth"></malb-icon>
		<p>solid_tooth</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_torah"></malb-icon>
		<p>solid_torah</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_torii_gate"></malb-icon>
		<p>solid_torii_gate</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tractor"></malb-icon>
		<p>solid_tractor</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_trademark"></malb-icon>
		<p>solid_trademark</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_traffic_light"></malb-icon>
		<p>solid_traffic_light</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_trailer"></malb-icon>
		<p>solid_trailer</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_train"></malb-icon>
		<p>solid_train</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tram"></malb-icon>
		<p>solid_tram</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_transgender_alt"></malb-icon>
		<p>solid_transgender_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_transgender"></malb-icon>
		<p>solid_transgender</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_trash_alt"></malb-icon>
		<p>solid_trash_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_trash_restore_alt"></malb-icon>
		<p>solid_trash_restore_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_trash_restore"></malb-icon>
		<p>solid_trash_restore</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_trash"></malb-icon>
		<p>solid_trash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tree"></malb-icon>
		<p>solid_tree</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_trophy"></malb-icon>
		<p>solid_trophy</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_truck_loading"></malb-icon>
		<p>solid_truck_loading</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_truck_monster"></malb-icon>
		<p>solid_truck_monster</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_truck_moving"></malb-icon>
		<p>solid_truck_moving</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_truck_pickup"></malb-icon>
		<p>solid_truck_pickup</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_truck"></malb-icon>
		<p>solid_truck</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tshirt"></malb-icon>
		<p>solid_tshirt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tty"></malb-icon>
		<p>solid_tty</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_tv"></malb-icon>
		<p>solid_tv</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_umbrella_beach"></malb-icon>
		<p>solid_umbrella_beach</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_umbrella"></malb-icon>
		<p>solid_umbrella</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_underline"></malb-icon>
		<p>solid_underline</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_undo_alt"></malb-icon>
		<p>solid_undo_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_undo"></malb-icon>
		<p>solid_undo</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_universal_access"></malb-icon>
		<p>solid_universal_access</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_university"></malb-icon>
		<p>solid_university</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_unlink"></malb-icon>
		<p>solid_unlink</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_unlock_alt"></malb-icon>
		<p>solid_unlock_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_unlock"></malb-icon>
		<p>solid_unlock</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_upload"></malb-icon>
		<p>solid_upload</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_alt_slash"></malb-icon>
		<p>solid_user_alt_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_alt"></malb-icon>
		<p>solid_user_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_astronaut"></malb-icon>
		<p>solid_user_astronaut</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_check"></malb-icon>
		<p>solid_user_check</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_circle"></malb-icon>
		<p>solid_user_circle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_clock"></malb-icon>
		<p>solid_user_clock</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_cog"></malb-icon>
		<p>solid_user_cog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_edit"></malb-icon>
		<p>solid_user_edit</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_friends"></malb-icon>
		<p>solid_user_friends</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_graduate"></malb-icon>
		<p>solid_user_graduate</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_injured"></malb-icon>
		<p>solid_user_injured</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_lock"></malb-icon>
		<p>solid_user_lock</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_md"></malb-icon>
		<p>solid_user_md</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_minus"></malb-icon>
		<p>solid_user_minus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_ninja"></malb-icon>
		<p>solid_user_ninja</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_nurse"></malb-icon>
		<p>solid_user_nurse</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_plus"></malb-icon>
		<p>solid_user_plus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_secret"></malb-icon>
		<p>solid_user_secret</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_shield"></malb-icon>
		<p>solid_user_shield</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_slash"></malb-icon>
		<p>solid_user_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_tag"></malb-icon>
		<p>solid_user_tag</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_tie"></malb-icon>
		<p>solid_user_tie</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user_times"></malb-icon>
		<p>solid_user_times</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_user"></malb-icon>
		<p>solid_user</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_users_cog"></malb-icon>
		<p>solid_users_cog</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_users_slash"></malb-icon>
		<p>solid_users_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_users"></malb-icon>
		<p>solid_users</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_utensil_spoon"></malb-icon>
		<p>solid_utensil_spoon</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_utensils"></malb-icon>
		<p>solid_utensils</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_vector_square"></malb-icon>
		<p>solid_vector_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_venus_double"></malb-icon>
		<p>solid_venus_double</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_venus_mars"></malb-icon>
		<p>solid_venus_mars</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_venus"></malb-icon>
		<p>solid_venus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_vest_patches"></malb-icon>
		<p>solid_vest_patches</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_vest"></malb-icon>
		<p>solid_vest</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_vial"></malb-icon>
		<p>solid_vial</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_vials"></malb-icon>
		<p>solid_vials</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_video_slash"></malb-icon>
		<p>solid_video_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_video"></malb-icon>
		<p>solid_video</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_vihara"></malb-icon>
		<p>solid_vihara</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_virus_slash"></malb-icon>
		<p>solid_virus_slash</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_virus"></malb-icon>
		<p>solid_virus</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_viruses"></malb-icon>
		<p>solid_viruses</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_voicemail"></malb-icon>
		<p>solid_voicemail</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_volleyball_ball"></malb-icon>
		<p>solid_volleyball_ball</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_volume_down"></malb-icon>
		<p>solid_volume_down</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_volume_mute"></malb-icon>
		<p>solid_volume_mute</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_volume_off"></malb-icon>
		<p>solid_volume_off</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_volume_up"></malb-icon>
		<p>solid_volume_up</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_vote_yea"></malb-icon>
		<p>solid_vote_yea</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_vr_cardboard"></malb-icon>
		<p>solid_vr_cardboard</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_walking"></malb-icon>
		<p>solid_walking</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_wallet"></malb-icon>
		<p>solid_wallet</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_warehouse"></malb-icon>
		<p>solid_warehouse</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_water"></malb-icon>
		<p>solid_water</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_wave_square"></malb-icon>
		<p>solid_wave_square</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_weight_hanging"></malb-icon>
		<p>solid_weight_hanging</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_weight"></malb-icon>
		<p>solid_weight</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_wheelchair"></malb-icon>
		<p>solid_wheelchair</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_wifi"></malb-icon>
		<p>solid_wifi</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_wind"></malb-icon>
		<p>solid_wind</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_window_close"></malb-icon>
		<p>solid_window_close</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_window_maximize"></malb-icon>
		<p>solid_window_maximize</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_window_minimize"></malb-icon>
		<p>solid_window_minimize</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_window_restore"></malb-icon>
		<p>solid_window_restore</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_wine_bottle"></malb-icon>
		<p>solid_wine_bottle</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_wine_glass_alt"></malb-icon>
		<p>solid_wine_glass_alt</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_wine_glass"></malb-icon>
		<p>solid_wine_glass</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_won_sign"></malb-icon>
		<p>solid_won_sign</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_wrench"></malb-icon>
		<p>solid_wrench</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_x_ray"></malb-icon>
		<p>solid_x_ray</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_yen_sign"></malb-icon>
		<p>solid_yen_sign</p>
	</div>
	<div class="item">
		<malb-icon width="50px" height="50px" color="var(--color-primary)" icon="solid_yin_yang"></malb-icon>
		<p>solid_yin_yang</p>
	</div>

</div>